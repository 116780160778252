import React, { ReactNode } from 'react';
import { ColDef, GridOptions, RowNode, ICellEditorParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { AircraftOperatorSettings } from '../../../Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { finalize, takeUntil } from 'rxjs/operators';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { AxiosError } from 'axios';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  UIStore,
  Utilities,
  NAME_TYPE_FILTERS,
  SettingsTypeModel,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';

interface Props {
  aircraftOperatorSettingsStore?: AircraftOperatorSettings;
}

const filtersSetup: IBaseGridFilterSetup<NAME_TYPE_FILTERS> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(NAME_TYPE_FILTERS),
  defaultFilterType: NAME_TYPE_FILTERS.NAME,
};

@inject('aircraftOperatorSettingsStore')
@observer
class RestrictionSeverity extends BaseGrid<Props, SettingsTypeModel, NAME_TYPE_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private get aircraftOperatorSettingsStore(): AircraftOperatorSettings {
    return this.props.aircraftOperatorSettingsStore as AircraftOperatorSettings;
  }

  @action
  private addNewType(): void {
    this._setColumnVisible('actionRenderer', true);
    this._addNewItems([ new SettingsTypeModel({ id: 0 }) ], { startEditing: true, colKey: 'name' });
    this.hasError = true;
  }

  private get rightContent(): ReactNode {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.addNewType()}
      >
        Add Restriction Severity
      </PrimaryButton>
    );
  }

  /* istanbul ignore next */
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.aircraftOperatorSettingsStore
      .getRestrictionSeverities()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(restrictionSeverities => (this.data = restrictionSeverities));
  }

  /* istanbul ignore next */
  private getEditableState({ data }: RowNode): boolean {
    return !Boolean(data.id);
  }

  private isAlreadyExists(id: number): boolean {
    if (this._isAlreadyExists([ 'name' ], id)) {
      this.showAlert('Name should be unique.', 'NameSettingsUnique');
      return true;
    }
    return false;
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        isRequired: true,
        ignoreNumber: true,
        rules: 'required|string|between:1,50',
      },
      editable: ({ node }) => this.getEditableState(node),
    },
    {
      headerName: 'Summary Description',
      field: 'summaryDescription',
      cellEditorParams: {
        isRequired: false,
        rules: 'string|between:0,1000',
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      colId: 'actionRenderer',
      maxWidth: 130,
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      suppressClickEdit: !SettingsModuleSecurity.isEditable,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
      },
      doesExternalFilterPass: node => {
        const { name, id } = node.data;
        return !id || this._isFilterPass({ [NAME_TYPE_FILTERS.NAME]: name });
      },
      onRowEditingStopped: () => {
        this._onRowEditingStopped();
        this._setColumnVisible('actionRenderer', true);
      },
    };
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  /* istanbul ignore next */
  private upsertRestrictionSeverity(rowIndex: number): void {
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.aircraftOperatorSettingsStore
      .upsertRestrictionSeverity(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: SettingsTypeModel) => {
          this._updateTableItem(rowIndex, response);
        },
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertRestrictionSeverity(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as NAME_TYPE_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          isHideSearchSelectControl={true}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default RestrictionSeverity;
