import React, { ReactNode } from 'react';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  RowEditingStartedEvent,
  ValueFormatterParams,
  GridReadyEvent,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { CONTACT_TYPE, HealthVendorContactModel, HealthVendorStore } from '../../../Shared';
import { styles } from './HealthVendorContact.style';
import { withStyles } from '@material-ui/core';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ENTITY_STATE,
  IClasses,
  UIStore,
  Utilities,
  regex,
  SettingsTypeModel,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { ConfirmDialog, CollapsibleWithButton } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridAutoComplete,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  isEditable: boolean;
  contacts: HealthVendorContactModel[];
  type: CONTACT_TYPE;
  healthVendorStore?: HealthVendorStore;
  onUpdate: (contacts: HealthVendorContactModel, removeModal?: boolean) => void;
  onContactEditing: (isEditing: boolean) => void;
}

@inject('healthVendorStore')
@observer
class HealthVendorContactGrid extends BaseGrid<Props, HealthVendorContactModel> {
  private readonly alertMessageId: string = 'healthVendorContactId';

  componentDidMount() {
    UIStore.setPageLoader(true);
    this.healthVendorStore
      .getContactLevels()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isEditable !== this.props.isEditable) {
      this._setColumnVisible('actionRenderer', this.props.isEditable);
    }
  }

  private get healthVendorStore(): HealthVendorStore {
    return this.props.healthVendorStore as HealthVendorStore;
  }

  private get isPhoneType(): boolean {
    return Utilities.isEqual(this.props.type, CONTACT_TYPE.PHONE);
  }

  private get contactRegex(): RegExp {
    return this.isPhoneType ? regex.all : regex.email;
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Contact Level',
      field: 'contactLevel',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Contact Level',
        getAutoCompleteOptions: () => this.healthVendorStore.contactLevels,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Contact',
      field: 'contact',
      cellEditorParams: {
        rules: `required|string|between:1,50|regex:${this.contactRegex}`,
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      cellEditorParams: {
        rules: 'string|between:1,100',
        ignoreNumber: true,
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressMenu: true,
      maxWidth: 130,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      gridActionProps: {
        showDeleteButton: true,
        getEditableState: () => this.props.isEditable,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customAutoComplete: AgGridAutoComplete,
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', this.props.isEditable);
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing || !this.props.isEditable) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onContactEditing(true);
      },
    };
  }

  private addNewType() {
    const contactType = new SettingsTypeModel({ id: this.isPhoneType ? 1 : 2 });
    this._addNewItems(
      [
        new HealthVendorContactModel({
          contactType,
        }),
      ],
      {
        startEditing: true,
        colKey: 'contactLevel',
      }
    );
    this.hasError = true;
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertContactType(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
        const model = this._getTableItem(rowIndex);
        this._cancelEditing(rowIndex, Utilities.isEqual(model.entityState || '', ENTITY_STATE.UNCHNAGED));
        this.props.onContactEditing(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmDelete(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        break;
    }
  }

  private isAlreadyExists(model: HealthVendorContactModel): boolean {
    this.data = this.props.contacts;
    const contact: string = this.getCellEditorInstance('contact').getValue();
    const contactLevel: SettingsTypeModel = this.getCellEditorInstance('contactLevel').getValue();
    const isExists = this.data.some(x => {
      return (
        Utilities.isEqual(x.contact, contact) &&
        Utilities.isEqual(x.contactLevel?.id, contactLevel?.id) &&
        !x.isSameData(model)
      );
    });
    if (isExists) {
      this.showAlert('Country level and Contact should be unique.', this.alertMessageId);
      return true;
    }
    return false;
  }

  /* istanbul ignore next */
  @action
  private upsertContactType(rowIndex: number): void {
    const { onUpdate, onContactEditing } = this.props;
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model)) {
      return;
    }
    this.gridApi.stopEditing();
    onContactEditing(false);
    onUpdate(model);
  }

  private confirmDelete(rowIndex: number): void {
    const { type } = this.props;
    const model: HealthVendorContactModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this._removeTableItems([ model ]);
      this.props.onUpdate(model, true);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message={`Are you sure you want to remove this ${type}?`}
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          this.deleteRequirement(rowIndex);
          this.props.onContactEditing(false);
        }}
      />
    );
  }

  private deleteRequirement(rowIndex: number): void {
    ModalStore.close();
    const model: HealthVendorContactModel = this._getTableItem(rowIndex);
    this._removeTableItems([ model ]);
    this.props.onUpdate(model, true);
  }

  public render(): ReactNode {
    const { classes, isEditable, type } = this.props as Required<Props>;
    return (
      <CollapsibleWithButton
        title={type}
        buttonText={`Add ${type}`}
        onButtonClick={() => this.addNewType()}
        isButtonDisabled={this.isProcessing || !isEditable}
      >
        <div className={classes.root}>
          <CustomAgGridReact
            isRowEditing={this.isRowEditing}
            rowData={this.props.contacts}
            gridOptions={this.gridOptions}
            disablePagination={this.isRowEditing}
          />
        </div>
      </CollapsibleWithButton>
    );
  }
}

export default withStyles(styles)(HealthVendorContactGrid);
