import React, { ReactNode } from 'react';
import { HealthVendorContactGrid } from '../index';
import { CONTACT_TYPE, HealthVendorContactModel } from '../../../Shared';
import { Field } from 'mobx-react-form';
import { action, observable } from 'mobx';
import { IOptionValue, UnsubscribableComponent } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl } from '@wings-shared/form-controls';
import { Collapsable } from '@wings-shared/layout';

interface Props {
  isEditable: boolean;
  contacts: HealthVendorContactModel[];
  onUpdate: (contacts: HealthVendorContactModel, removeContact?: boolean) => void;
  onContactEditing: (isEditing: boolean) => void;
  getField: (fieldKey: string) => Field;
  onChange: (value: IOptionValue, fieldKey: string) => void;
}

export class HealthVendorContact extends UnsubscribableComponent<Props> {
  @observable private isPhoneEditing: boolean = false;
  @observable private isEmailEditing: boolean = false;

  @action
  private onContactEditing(isPhone: boolean, isEmail: boolean): void {
    this.isPhoneEditing = isPhone;
    this.isEmailEditing = isEmail;
    this.props.onContactEditing(isPhone || isEmail);
  }

  public render(): ReactNode {
    const { onUpdate, isEditable, getField, onChange, contacts } = this.props;
    return (
      <>
        <Collapsable title="Survey Link">
          <ViewInputControl
            field={getField('surveyLink')}
            isEditable={isEditable}
            onValueChange={(option, _) => onChange(option, 'surveyLink')}
            type={EDITOR_TYPES.LINK}
          />
        </Collapsable>
        <HealthVendorContactGrid
          isEditable={isEditable}
          type={CONTACT_TYPE.PHONE}
          contacts={contacts.filter(x => x.isPhoneContact)}
          onUpdate={(data, removeModal) => onUpdate(data, removeModal)}
          onContactEditing={isEditing => this.onContactEditing(isEditing, this.isEmailEditing)}
        />
        <HealthVendorContactGrid
          isEditable={isEditable}
          type={CONTACT_TYPE.EMAIL}
          contacts={contacts.filter(x => x.isEmailContact)}
          onUpdate={(data, removeModal) => onUpdate(data, removeModal)}
          onContactEditing={isEditing => this.onContactEditing(this.isPhoneEditing, isEditing)}
        />
      </>
    );
  }
}

export default HealthVendorContact;
