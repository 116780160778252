import React, { ReactNode } from 'react';
import { VIEW_MODE, ModelStatusOptions } from '@wings/shared';
import {
  AgGridActions,
  AgGridChipView,
  AgGridGroupHeader,
  AgGridStatusBadge,
  BaseGrid,
  CustomAgGridReact,
} from '@wings-shared/custom-ag-grid';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  FilterModifiedEvent,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  ScheduleRestrictionsStore,
  ScheduleRestrictionsModel,
  SCHEDULE_RESTRICTIONS_FILTERS,
  RestrictionModuleSecurity,
  SettingsStore,
  updateRestrictionSidebarOptions,
} from '../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { gridFilters } from './fields';
import { action, observable } from 'mobx';
import {
  DATE_FORMAT,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  ViewPermission,
  SearchStore,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IGridSortFilter,
} from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';

interface Props {
  viewMode?: VIEW_MODE;
  scheduleRestrictionsStore?: ScheduleRestrictionsStore;
  settingsStore?: SettingsStore;
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
}

const filterSetup: IBaseGridFilterSetup<SCHEDULE_RESTRICTIONS_FILTERS> = {
  defaultPlaceHolder: 'Search by Name Restriction Type',
  apiFilterDictionary: gridFilters,
  defaultFilterType: SCHEDULE_RESTRICTIONS_FILTERS.RESTRICTION_TYPE,
  filterTypesOptions: Object.values(SCHEDULE_RESTRICTIONS_FILTERS),
};

@inject('scheduleRestrictionsStore', 'settingsStore', 'sidebarStore')
@observer
class ScheduleRestrictions extends BaseGrid<Props, ScheduleRestrictionsModel, SCHEDULE_RESTRICTIONS_FILTERS> {
  @observable public searchChips: ISelectOption[] = [];

  constructor(props) {
    super(props, filterSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateRestrictionSidebarOptions('Schedule Restrictions'), 'restrictions');
    this.loadScheduleRestrictions();
    this.onAdvanceSearch$().subscribe(() => this.loadScheduleRestrictions());
    this.setMiniFilterTextDebounce();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private get scheduleRestrictionsStore(): ScheduleRestrictionsStore {
    return this.props.scheduleRestrictionsStore as ScheduleRestrictionsStore;
  }

  private get isStatusFilter(): boolean {
    return Utilities.isEqual(this.selectedOption, SCHEDULE_RESTRICTIONS_FILTERS.STATUS);
  }

  private get __searchFilters(): IAPIGridRequest {
    if (this.isStatusFilter && this.searchValue?.length) {
      return {
        filterCollection: JSON.stringify([{ propertyName: 'Status.Name', propertyValue: this.searchValue }]),
      };
    }
    return this._searchFilters;
  }

  /* istanbul ignore next */
  @action
  private loadScheduleRestrictions(pageRequest?: IAPIGridRequest): void {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: this.pagination.pageSize,
      ...pageRequest,
      ...this.__searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };

    UIStore.setPageLoader(true);
    this.scheduleRestrictionsStore
      .getScheduleRestrictions(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: IAPIPageResponse) => {
        this.pagination = new GridPagination({ ...response });
        this.data = response.results;
        this.gridAdvancedSearchFilterApplied();
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption(
            (selectedOption as SCHEDULE_RESTRICTIONS_FILTERS) || SCHEDULE_RESTRICTIONS_FILTERS.RESTRICTION_TYPE
          );
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Restriction Type',
      field: 'restrictionType',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'restrictionType', 2) },
    },
    {
      headerName: 'Restricting Entity',
      field: 'restrictingEntities',
      cellRenderer: 'agGridChipView',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'restrictingEntities', 2) },
      cellRendererParams: {
        tooltipField: 'entityName',
      },
    },
    {
      headerName: 'Departure Level',
      field: 'departureLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'departureLevel', 2) },
    },
    {
      headerName: 'Departure Entity',
      field: 'departureLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'departureLevelEntities', 2) },
    },
    {
      headerName: 'Arrival Level',
      field: 'arrivalLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'arrivalLevel', 2) },
    },
    {
      headerName: 'Arrival Entity',
      field: 'arrivalLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'arrivalLevelEntities', 2) },
    },
    {
      headerName: 'Overflight Level',
      field: 'overFlightLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'overFlightLevel', 2) },
    },
    {
      headerName: 'Overflight Entity',
      field: 'overFlightLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'overFlightLevelEntities', 2) },
    },
    {
      headerName: 'Far Types',
      field: 'farTypes',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'farTypes', 2) },
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('start', 'status', 2) },
      filterValueGetter: ({ data }: ValueGetterParams) => data.status?.label,
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'other details',
      groupId: 'otherDetails',
      suppressMenu: true,
      children: [
        {
          headerName: 'Start Date',
          field: 'startDate',
          headerComponent: 'customHeader',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          columnGroupShow: 'open',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'Validated Date',
          field: 'validatedDate',
          columnGroupShow: 'open',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'Validated By',
          field: 'validatedBy',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: { ...this.nameSearchFilterParams('start', 'status', 2) },
        },
        {
          headerName: 'Validation Notes',
          field: 'validationNotes',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: { ...this.nameSearchFilterParams('contains', 'validationNotes', 2) },
        },
        {
          headerName: '',
          field: 'actionRenderer',
          cellRenderer: 'actionRenderer',
          cellEditor: 'actionRenderer',
          filter: false,
          sortable: false,
          suppressSizeToFit: true,
          suppressMenu: true,
          minWidth: 120,
          maxWidth: 150,
          cellStyle: { ...cellStyle() },
        },
      ],
    },
  ];

  private setSelectedOption(selectedOption: SCHEDULE_RESTRICTIONS_FILTERS): void {
    this.searchChips = [];
    this._setSelectedOption(selectedOption);
    this.gridApi?.onFilterChanged();
  }

  /* istanbul ignore next */
  @action
  protected onChipAddOrRemove(searchChips: ISelectOption[]): void {
    // if multiple items then select last item
    this.searchChips = searchChips?.length > 1 ? [].concat(searchChips?.pop() as any) : searchChips;
    this._setSearchValue(this.searchChips[0]?.label);
    if (!this.gridApi) {
      return;
    }
    this.gridApi.onFilterChanged();
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      gridActionProps: {
        isActionMenu: true,
        onAction: () => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue as any);
          }
        },
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !RestrictionModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `${node.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.VIEW,
            to: node => `${node.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
        ],
      },
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        cellRendererParams: {
          ...baseOptions.defaultColDef?.cellRendererParams,
          chipLabelField: 'code',
        },
      },
      pagination: false,
      isExternalFilterPresent: () => false,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        agGridChipView: AgGridChipView,
        customHeader: AgGridGroupHeader,
        statusRenderer: AgGridStatusBadge,
      },
      onFilterChanged: () => this.gridAdvancedSearchFilters.length === 0 && this.loadScheduleRestrictions(),
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadScheduleRestrictions();
      },
      onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={RestrictionModuleSecurity.isEditable}>
        <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Schedule Restriction" />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this.setSelectedOption(selectedOption as SCHEDULE_RESTRICTIONS_FILTERS)}
          options={
            Utilities.isEqual(this.selectedOption, SCHEDULE_RESTRICTIONS_FILTERS.STATUS) ? ModelStatusOptions : []
          }
          isChipInputControl={this.isStatusFilter}
          chipValue={this.searchChips}
          onChipAddOrRemove={(chips: ISelectOption[]) => this.onChipAddOrRemove(chips)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          onPaginationChange={request => this.loadScheduleRestrictions(request)}
        />
      </>
    );
  }
}

export default ScheduleRestrictions;
