import React, { Component, ReactNode } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { UpsertSettings } from '@wings/shared';
import { action, observable } from 'mobx';
import { HealthAuthStore, SettingsStore, SETTING_ID, HealthVendorStore, AircraftOperatorSettings } from '../Shared';
import { categoryList, settingList } from './Fields';
import { styles } from './Settings.style';
import {
  QuarantineLocation,
  LeadTimeIndicator,
  FlightAllowed,
  TraveledHistorySubCategory,
  RestrictionSource,
  UWAAllowableAction,
  RestrictionSeverity,
  AircraftOperatorRestrictionType,
} from './Components';
import { Utilities, regex, IClasses, SelectOption } from '@wings-shared/core';
import { SettingCategoryControl } from '@wings-shared/form-controls';

interface Props {
  settingsStore?: SettingsStore;
  healthAuthStore?: HealthAuthStore;
  healthVendorStore?: HealthVendorStore;
  aircraftOperatorSettingsStore?: AircraftOperatorSettings;
  classes?: IClasses;
}

@inject('settingsStore', 'healthAuthStore', 'healthVendorStore', 'aircraftOperatorSettingsStore')
@observer
class Settings extends Component<Props> {
  @observable private activeCategory: number = 1;
  @observable private activeSubCategory: number = 1;

  @action
  private setActiveCategory(categoryID: number): void {
    this.activeCategory = categoryID;
    this.setActiveSubCategory(this.subCategories[0].value as number);
  }

  @action
  private setActiveSubCategory(categoryID: number): void {
    this.activeSubCategory = categoryID;
  }

  private get subCategories(): SelectOption[] {
    return Utilities.customArraySort(settingList, 'settingLabel')
      .filter(setting => setting.categoryId === this.activeCategory)
      .map(setting => new SelectOption({ name: setting.settingLabel, value: setting.settingId }));
  }

  private renderSetting(): ReactNode {
    const { settingsStore, healthAuthStore, healthVendorStore, aircraftOperatorSettingsStore } = this.props as Required<
      Props
    >;
    switch (this.activeSubCategory) {
      case SETTING_ID.UWA_ALLOWABLE_ACTION:
        return <UWAAllowableAction />;
      case SETTING_ID.LANDING_OR_OVERFLIGHT:
        return (
          <UpsertSettings
            type="Landing Or Overflight"
            key="landingOrOverflight"
            upsertSettings={data => settingsStore.upsertLandingOrOverflight(data)}
            getSettings={() => settingsStore.getLandingOrOverflights()}
            settingsData={settingsStore.landingOrOverflights}
            isNameUnique={false}
            isEditable={false}
          />
        );
      case SETTING_ID.RESTRICTION_TYPE:
        return (
          <UpsertSettings
            type="Restriction Type"
            key="restrictionType"
            upsertSettings={data => settingsStore.upsertRestrictionType(data)}
            getSettings={() => settingsStore.getRestrictionTypes()}
            settingsData={settingsStore.restrictionTypes}
            isNameUnique={false}
            isEditable={false}
          />
        );
      case SETTING_ID.APPROVAL_TYPE:
        return (
          <UpsertSettings
            type="Approval Type"
            key="approvalType"
            upsertSettings={data => settingsStore.upsertApprovalType(data)}
            getSettings={() => settingsStore.getApprovalTypes()}
            settingsData={settingsStore.approvalTypes}
            isNameUnique={false}
            isEditable={false}
          />
        );
      case SETTING_ID.RESTRICTION_APPLIES:
        return (
          <UpsertSettings
            type="Restriction Apply"
            key="restrictionApply"
            upsertSettings={data => settingsStore.upsertRestrictionApply(data)}
            getSettings={() => settingsStore.getRestrictionApplies()}
            settingsData={settingsStore.restrictionApplies}
            isNameUnique={false}
            isEditable={false}
          />
        );
      case SETTING_ID.RESTRICTION_SOURCE:
        return <RestrictionSource />;
      case SETTING_ID.RESTRICTION_LEVEL:
        return (
          <UpsertSettings
            type="Restriction Level"
            key="restrictionLevel"
            upsertSettings={data => settingsStore.upsertRestrictionLevel(data)}
            getSettings={() => settingsStore.getRestrictionLevels()}
            settingsData={settingsStore.restrictionLevels}
            isNameUnique={false}
            isEditable={false}
          />
        );
      case SETTING_ID.SOURCE_TYPE:
        return (
          <UpsertSettings
            type="Source Type"
            key="sourceType"
            isEditable={false}
            upsertSettings={data => settingsStore.upsertSourceType(data)}
            getSettings={() => settingsStore.getSourceTypes()}
            settingsData={settingsStore.sourceTypes}
          />
        );
      case SETTING_ID.ACCESS_LEVEL:
        return (
          <UpsertSettings
            type="Access Level"
            key="accessLevel"
            isEditable={false}
            upsertSettings={data => settingsStore.upsertAccessLevel(data)}
            getSettings={() => settingsStore.getAccessLevels()}
            settingsData={settingsStore.accessLevels}
          />
        );
      case SETTING_ID.AUTHORIZATION_LEVEL:
        return (
          <UpsertSettings
            type="Authorization Level"
            key="authorizationLevel"
            upsertSettings={data => healthAuthStore.upsertAuthorizationLevel(data)}
            getSettings={() => healthAuthStore.getAuthorizationLevels()}
            settingsData={healthAuthStore.authorizationLevels}
            isEditable={false}
          />
        );
      case SETTING_ID.INFECTION_TYPE:
        return (
          <UpsertSettings
            type="Infection Type"
            key="infectionType"
            upsertSettings={data => healthAuthStore.upsertInfectionType(data)}
            getSettings={() => healthAuthStore.getInfectionTypes()}
            settingsData={healthAuthStore.infectionTypes}
          />
        );
      case SETTING_ID.AFFECTED_TYPE:
        return (
          <UpsertSettings
            type="Affected Type"
            key="affectedType"
            upsertSettings={data => healthAuthStore.upsertAffectedType(data)}
            getSettings={() => healthAuthStore.getAffectedTypes()}
            settingsData={healthAuthStore.affectedTypes}
          />
        );
      case SETTING_ID.HEALTH_FORM:
        return (
          <UpsertSettings
            type="Health Form"
            key="healthForm"
            upsertSettings={data => settingsStore.upsertHealthForm(data)}
            getSettings={() => settingsStore.getHealthForms()}
            settingsData={settingsStore.healthForms}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.TEST_TYPE:
        return (
          <UpsertSettings
            type="Test Type"
            key="testType"
            upsertSettings={data => settingsStore.upsertTestType(data)}
            getSettings={() => settingsStore.getTestTypes()}
            settingsData={settingsStore.testTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.CONTACT_LEVEL:
        return (
          <UpsertSettings
            type="Contact Level"
            key="contactLevel"
            upsertSettings={data => healthVendorStore.upsertContactLevels(data)}
            getSettings={() => healthVendorStore.getContactLevels()}
            settingsData={healthVendorStore.contactLevels}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.STATUS:
        return (
          <UpsertSettings
            type="Status"
            key="status"
            upsertSettings={() => null}
            getSettings={() => settingsStore.getStatus()}
            settingsData={settingsStore.status}
            isEditable={false}
            hideAddNewButton={true}
            isExactMatch={true}
          />
        );
      case SETTING_ID.WHO_CAN_LEAVE_AIRCRAFT:
        return (
          <UpsertSettings
            type="Who Can Leave Aircraft"
            key="whoCanLeaveAircraft"
            upsertSettings={data => settingsStore.upsertWhoCanLeaveAircraft(data)}
            getSettings={() => settingsStore.getWhoCanLeaveAircraft()}
            settingsData={settingsStore.whoCanLeaveAircraft}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.VACCINATION_PRIVILEGE:
        return (
          <UpsertSettings
            type="Vaccination Privilege"
            key="VaccinationPrivilege"
            upsertSettings={data => settingsStore.upsertVaccinationPrivilege(data)}
            getSettings={() => settingsStore.getVaccinationPrivileges()}
            settingsData={settingsStore.vaccinationPrivileges}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
            isEditable={false}
          />
        );
      case SETTING_ID.VACCINE_MANUFACTURER:
        return (
          <UpsertSettings
            type="Vaccine Manufacturer"
            key="VaccinationManufacturer"
            upsertSettings={data => settingsStore.upsertVaccineManufacturer(data)}
            getSettings={() => settingsStore.getVaccineManufacturers()}
            settingsData={settingsStore.vaccineManufacturers}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.QUARANTINE_LOCATION:
        return <QuarantineLocation />;
      case SETTING_ID.LEAD_TIME_INDICATOR:
        return <LeadTimeIndicator />;
      case SETTING_ID.FLIGHTS_ALLOWED:
        return <FlightAllowed />;
      case SETTING_ID.OVERFLIGHT_LEVEL:
        return (
          <UpsertSettings
            type="Overflight Level"
            key="overflightLevels"
            upsertSettings={data => settingsStore.upsertOverflightLevel(data)}
            getSettings={() => settingsStore.getOverflightLevels()}
            settingsData={settingsStore.overflightLevels}
            isEditable={false}
          />
        );
      case SETTING_ID.ARRIVAL_LEVEL:
        return (
          <UpsertSettings
            type="Arrival Level"
            key="arrivalLevels"
            upsertSettings={data => settingsStore.upsertArrivalLevel(data)}
            getSettings={() => settingsStore.getArrivalLevels()}
            settingsData={settingsStore.arrivalLevels}
            isEditable={false}
          />
        );
      case SETTING_ID.BOARDING_TYPE:
        return (
          <UpsertSettings
            type="Boarding Type"
            key="boardingTypes"
            upsertSettings={data => settingsStore.upsertBoardingType(data)}
            getSettings={() => settingsStore.getBoardingTypes()}
            settingsData={settingsStore.boardingTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.DEPARTURE_LEVEL:
        return (
          <UpsertSettings
            type="Departure Level"
            key="scheduleDepartureLevels"
            upsertSettings={data => settingsStore.upsertScheduleDepartureLevel(data)}
            getSettings={() => settingsStore.getScheduleDepartureLevels()}
            settingsData={settingsStore.scheduleDepartureLevels}
            isEditable={false}
          />
        );
      case SETTING_ID.STAY_LENGTH_CATEGORY:
        return (
          <UpsertSettings
            type="Stay Length Category"
            key="stayLengthCategory"
            upsertSettings={data => settingsStore.upsertStayLengthCategory(data)}
            getSettings={() => settingsStore.getStayLengthCategories()}
            settingsData={settingsStore.stayLengthCategories}
          />
        );
      case SETTING_ID.CURFEW_HOUR_TYPE:
        return (
          <UpsertSettings
            type="Curfew Hour Type"
            key="curfewHourTypes"
            upsertSettings={data => settingsStore.upsertCurfewHourType(data)}
            getSettings={() => settingsStore.getCurfewHourTypes()}
            settingsData={settingsStore.curfewHourTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.PPE_TYPE:
        return (
          <UpsertSettings
            type="PPE Type"
            key="ppeType"
            upsertSettings={data => settingsStore.upsertPPEType(data)}
            getSettings={() => settingsStore.getPPETypes()}
            settingsData={settingsStore.ppeTypes}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.ID_TYPE:
        return (
          <UpsertSettings
            type="Id Type"
            key="idTypes"
            upsertSettings={data => settingsStore.upsertIdType(data)}
            getSettings={() => settingsStore.getIdTypes()}
            settingsData={settingsStore.idTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.AIRCRAFT_OPERATOR_RESTRICTION_TYPE:
        return <AircraftOperatorRestrictionType />;
      case SETTING_ID.EFFECTED_ENTITY_TYPE:
        return (
          <UpsertSettings
            type="Effected Entity Type"
            key="effectedEntityTypes"
            upsertSettings={data => aircraftOperatorSettingsStore.upsertEffectedEntityType(data)}
            getSettings={() => aircraftOperatorSettingsStore.getEffectedEntityTypes()}
            settingsData={aircraftOperatorSettingsStore.effectedEntityTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
            isEditable={false}
          />
        );
      case SETTING_ID.ENFORCEMENT_AGENCY:
        return (
          <UpsertSettings
            type="Enforcement Agency"
            key="enforcementAgencies"
            upsertSettings={data => aircraftOperatorSettingsStore.upsertEnforcementAgency(data)}
            getSettings={() => aircraftOperatorSettingsStore.getEnforcementAgencies()}
            settingsData={aircraftOperatorSettingsStore.enforcementAgencies}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
            isEditable={false}
          />
        );
      case SETTING_ID.RESTRICTION_SEVERITY:
        return <RestrictionSeverity />;
      case SETTING_ID.APPROVAL_TYPE_REQUIRED:
        return (
          <UpsertSettings
            type="Approval Type Required"
            key="approvalTypesRequired"
            upsertSettings={data => aircraftOperatorSettingsStore.upsertApprovalTypeRequired(data)}
            getSettings={() => aircraftOperatorSettingsStore.getApprovalTypesRequired()}
            settingsData={aircraftOperatorSettingsStore.approvalTypesRequired}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
            isEditable={false}
          />
        );
      case SETTING_ID.RESTRICTION_FORM:
        return (
          <UpsertSettings
            type="Required Documents/Forms"
            key="requiredForms"
            upsertSettings={data => aircraftOperatorSettingsStore.upsertRestrictionForm(data)}
            getSettings={() => aircraftOperatorSettingsStore.getRestrictionForms()}
            settingsData={aircraftOperatorSettingsStore.restrictionForms}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
            isEditable={false}
          />
        );
      case SETTING_ID.TRAVELED_HISTORY_CATEGORY:
        return (
          <UpsertSettings
            type="Travel History Category"
            key="travelHistoryCategory"
            upsertSettings={data => settingsStore.upsertTravelHistoryCategory(data)}
            getSettings={() => settingsStore.getTraveledHistoryCategories()}
            settingsData={settingsStore.traveledHistoryCategories}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.TRAVELED_HISTORY_SUB_CATEGORY:
        return <TraveledHistorySubCategory />;
      case SETTING_ID.UWA_ALLOWABLE_SERVICE:
        return (
          <UpsertSettings
            type="UWA Allowable Service"
            key="uwaAllowableService"
            upsertSettings={data => settingsStore.upsertUWAAllowableService(data)}
            getSettings={() => settingsStore.getUWAAllowableServices()}
            settingsData={settingsStore.uwaAllowableServices}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      default:
      case SETTING_ID.TRAVELLER_TYPE:
        return (
          <UpsertSettings
            type="Traveller Type"
            key="travellerType"
            upsertSettings={data => settingsStore.upsertTravellerType(data)}
            getSettings={() => settingsStore.getTravellerTypes()}
            settingsData={settingsStore.travellerTypes}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
      case SETTING_ID.VACCINATION_STATUS:
        return (
          <UpsertSettings
            type="Vaccination Status"
            key="vaccinationStatus"
            upsertSettings={data => settingsStore.upsertVaccinationStatus(data)}
            getSettings={() => settingsStore.getVaccinationStatus()}
            settingsData={settingsStore.vaccinationStatus}
            isNameUnique={true}
            regExp={regex.all}
            maxLength={50}
          />
        );
    }
  }

  public render() {
    const { classes } = this.props as Required<Props>;
    return (
      <>
        <div className={classes.heading}>
          <Typography variant="h5">Restriction Settings</Typography>
        </div>
        <div className={classes.root}>
          <div className={classes.selectSettingContainer}>
            <SettingCategoryControl
              title="Category"
              value={this.activeCategory}
              selectOptions={categoryList}
              onOptionChange={category => this.setActiveCategory(category)}
            />
            <SettingCategoryControl
              title="Sub category"
              value={this.activeSubCategory}
              selectOptions={this.subCategories}
              onOptionChange={settingLabel => this.setActiveSubCategory(settingLabel)}
            />
          </div>
          <div className={classes.settingWrapper}>{this.renderSetting()}</div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Settings);
export { Settings as PureSettings };
