import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE, ModelStatusOptions, EntityOptionsStore, CountryModel } from '@wings/shared';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { NavigateFunction } from 'react-router';
import {
  SettingsStore,
  RestrictionModuleSecurity,
  AircraftOperatorRestrictionsStore,
  AircraftOperatorRestrictionsModel,
  AircraftOperatorSettings,
  updateRestrictionSidebarOptions,
} from '../../Shared';
import { fields } from './Fields';
import { withStyles } from '@material-ui/styles';
import { styles } from './UpsertAircraftOperatorRestriction.styles';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  DATE_FORMAT,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  IOptionValue,
  SEARCH_ENTITY_TYPE,
  StatusTypeModel,
  UIStore,
  Utilities,
  baseEntitySearchFilters,
  tapWithAction,
  withRouter,
  EntityMapModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { AuditFields, EDITOR_TYPES, ViewInputControlsGroup, IGroupInputControls } from '@wings-shared/form-controls';
import {
  DetailsEditorHeaderSection,
  DetailsEditorWrapper,
  ConfirmNavigate,
  ConfirmDialog,
  SidebarStore,
} from '@wings-shared/layout';

interface Props {
  params?: { id: string; viewMode: VIEW_MODE };
  viewMode?: VIEW_MODE;
  aircraftOperatorRestrictionsStore?: AircraftOperatorRestrictionsStore;
  aircraftOperatorSettingsStore?: AircraftOperatorSettings;
  entityOptionsStore?: EntityOptionsStore;
  settingsStore?: SettingsStore;
  classes?: IClasses;
  navigate: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject(
  'settingsStore',
  'aircraftOperatorRestrictionsStore',
  'entityOptionsStore',
  'aircraftOperatorSettingsStore',
  'sidebarStore'
)
@observer
class UpsertAircraftOperatorRestriction extends BaseUpsertComponent<Props, AircraftOperatorRestrictionsModel> {
  private readonly backNavLink: string = '/restrictions/aircraft-operator-restrictions';
  private readonly restrictionChecks = [
    'restrictionAppliedToLicenseHolder',
    'restrictionAppliedToRegistries',
    'restrictionAppliedToAllFlights',
    'restrictionAppliedToOperators',
    'restrictionAppliedToPassportedPassenger',
  ];
  @observable private aircraftOperatorRestrictionModel: AircraftOperatorRestrictionsModel;
  @observable private restrictionForms: EntityMapModel[] = [];
  @observable private uwaAllowableServices: EntityMapModel[] = [];

  constructor(p: Props) {
    super(p, fields, baseEntitySearchFilters);
    const { params } = this.props as Required<Props>;
    this.viewMode = VIEW_MODE[params.viewMode.toUpperCase()] || VIEW_MODE.EDIT;
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(
      updateRestrictionSidebarOptions('Aircraft Operator Restrictions'),
      'restrictions'
    );
    this.loadAircraftOperatorRestrictions();
  }

  /* istanbul ignore next */
  private get aircraftOperatorRestrictionsStore(): AircraftOperatorRestrictionsStore {
    return this.props.aircraftOperatorRestrictionsStore as AircraftOperatorRestrictionsStore;
  }

  /* istanbul ignore next */
  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private get entityOptionsStore(): EntityOptionsStore {
    return this.props.entityOptionsStore as EntityOptionsStore;
  }

  /* istanbul ignore next */
  private get aircraftOperatorSettings(): AircraftOperatorSettings {
    return this.props.aircraftOperatorSettingsStore as AircraftOperatorSettings;
  }

  private get aircraftOperatorRestrictionId(): number | null {
    const { params } = this.props as Required<Props>;
    return Utilities.getNumberOrNullValue(params.id);
  }

  /* istanbul ignore next */
  private get effectedEntityType(): SEARCH_ENTITY_TYPE {
    return this.getField('effectedEntityType').value?.label;
  }

  private get hasValidRestrictionChecks(): boolean {
    return this.restrictionChecks.some(fieldKey => this.getField(fieldKey).value);
  }

  private get isAllowedServicesEditable(): boolean {
    const allowableActions = this.getField('uwaAllowableActions').value;
    return (
      Utilities.isEqual(allowableActions?.label, 'Partial Support') ||
      Utilities.isEqual(allowableActions?.label, 'Partial Support - Russia')
    );
  }

  private get hasError(): boolean {
    return this.form.hasError || !this.hasValidRestrictionChecks;
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    const { classes } = this.props as Required<Props>;
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'effectedEntityType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorSettings.effectedEntityTypes,
          },
          {
            fieldKey: 'effectedEntity',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.entityOptionsStore.getEntityOptions(this.effectedEntityType),
            isDisabled: !Boolean(this.effectedEntityType),
          },
          {
            fieldKey: 'nationalities',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.aircraftOperatorRestrictionsStore.countries,
            getChipLabel: country => (country as CountryModel).isO2Code,
            showChipTooltip: true,
          },
        ],
      },
      {
        title: 'Restriction Areas:',
        inputControls: [
          {
            fieldKey: 'restrictionType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorSettings.aircraftOperatorRestrictionTypes,
          },
          {
            fieldKey: 'restrictionAppliedToLicenseHolder',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'restrictionAppliedToRegistries',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'restrictionAppliedToAllFlights',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'restrictionAppliedToOperators',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'restrictionAppliedToPassportedPassenger',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'exceptForeignOperators',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'sfc',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'lowerLimitFL',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: this.getField('sfc').value,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'unl',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'upperLimitFL',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: this.getField('unl').value,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'restrictionSource',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.restrictionSources,
          },
          {
            fieldKey: 'restrictingCountry',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorRestrictionsStore.countries,
          },
          {
            fieldKey: 'restrictionSeverity',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorSettings.restrictionSeverities,
          },
          {
            fieldKey: 'approvalTypeRequired',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorSettings.approvalTypesRequired,
          },
          {
            fieldKey: 'aircraftOperatorRestrictionForms',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.restrictionForms,
            multiple: true,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            maxDate: this.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            minDate: this.getField('startDate').value,
          },
          {
            fieldKey: 'uwaAllowableActions',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.uwaAllowableActions,
          },
          {
            fieldKey: 'uwaAllowableServices',
            type: EDITOR_TYPES.DROPDOWN,
            isDisabled: !this.isAllowedServicesEditable,
            options: this.uwaAllowableServices,
            multiple: true,
          },
          {
            fieldKey: 'enforcementAgency',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.aircraftOperatorSettings.enforcementAgencies,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'notamId',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'notamExpiryDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
          },
          {
            fieldKey: 'link',
            type: EDITOR_TYPES.LINK,
          },
        ],
      },
      {
        title: '',
        inputControlClassName: classes.summary,
        inputControls: [
          {
            fieldKey: 'summary',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: true,
            multiline: true,
            isHidden: this.isAddNew,
            rows: 5,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'accessLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.accessLevels,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: ModelStatusOptions.map(m => StatusTypeModel.deserialize({ id: Number(m.value), name: m.label })),
          },
          {
            fieldKey: 'sourceType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.sourceTypes,
          },
        ],
      },
    ];
  }

  /* istanbul ignore next */
  private validateInputs() {
    // validate checkbox
    this.restrictionChecks.forEach(_key => {
      this.getField(_key).set('label', `${this.getFieldLabel(_key)}${this.hasValidRestrictionChecks ? '' : '*'}`);
    });
  }

  /* istanbul ignore next */
  private loadAircraftOperatorRestrictions(): void {
    if (!this.aircraftOperatorRestrictionId) {
      return;
    }
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        { propertyName: 'AircraftOperatorRestrictionId', propertyValue: this.aircraftOperatorRestrictionId },
      ]),
    };
    UIStore.setPageLoader(true);
    this.aircraftOperatorRestrictionsStore
      .getAircraftOperatorRestrictions(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: IAPIPageResponse) => {
        this.aircraftOperatorRestrictionModel = response.results[0];
        this.setFormValues(this.aircraftOperatorRestrictionModel);
        this.validateInputs();
      });
  }

  /* istanbul ignore next */
  private upsertAircraftOperatorRestrictions(): void {
    const model = new AircraftOperatorRestrictionsModel({
      ...this.aircraftOperatorRestrictionModel,
      ...this.form.values(),
    });
    UIStore.setPageLoader(true);
    this.aircraftOperatorRestrictionsStore
      .upsertAircraftOperatorRestrictions(model.serialize())
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (responseModel: AircraftOperatorRestrictionsModel) => {
          this.aircraftOperatorRestrictionModel = responseModel;
          this.form.reset();
          this.form.set(this.aircraftOperatorRestrictionModel);
          const viewMode = this.props.params?.viewMode.toUpperCase();
          // if new aircraft operator then redirect to edit screen
          if (!Boolean(model?.id) && viewMode === VIEW_MODE.NEW) {
            this.props.navigate(`${this.backNavLink}/${responseModel.id}/edit`);
            return;
          }
          if (viewMode === VIEW_MODE.DETAILS) {
            this.setViewMode(VIEW_MODE.DETAILS);
          }
          this.validateInputs();
        },
        error: error => this.showAlert(error.message, 'upsertAirportBase'),
      });
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.SAVE:
        this.upsertAircraftOperatorRestrictions();
        break;
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this.confirmClose();
        break;
    }
  }

  private confirmClose(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode !== VIEW_MODE.DETAILS) {
      this.props.navigate(this.backNavLink, this.noBlocker);
      return;
    }
    if (!(this.form.touched || this.form.changed)) {
      this.onCancel();
      return;
    }
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Cancellation"
        message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
        yesButton="Yes"
        onNoClick={() => {
          ModalStore.close();
        }}
        onYesClick={() => {
          this.onCancel();
          ModalStore.close();
        }}
      />
    );
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.form.reset();
      this.setFormValues(this.aircraftOperatorRestrictionModel);
      return;
    }
    this.props.navigate(this.backNavLink, this.noBlocker);
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'nationalities':
        if (!(value as CountryModel[])?.length) {
          this.aircraftOperatorRestrictionsStore.countries = [];
        }
        break;
      case 'restrictingCountry':
        if (!value) {
          this.aircraftOperatorRestrictionsStore.countries = [];
        }
        break;
      case 'effectedEntityType':
        this.clearFormFields([ 'effectedEntity' ]);
        this.entityOptionsStore.clearEntity(this.effectedEntityType);
        break;
      case 'uwaAllowableActions':
        // clear UWA Allowable Services
        this.getField('uwaAllowableServices').clear();
        break;
      case 'restrictionAppliedToLicenseHolder':
      case 'restrictionAppliedToRegistries':
      case 'restrictionAppliedToAllFlights':
      case 'restrictionAppliedToOperators':
      case 'restrictionAppliedToPassportedPassenger':
        this.validateInputs();
        break;
      case 'sfc':
        // clear Lower Limit FL
        this.getField('lowerLimitFL').set('');
        break;
      case 'unl':
        // clear Upper Limit FL
        this.getField('upperLimitFL').set('');
        break;
    }
  }

  // Search Entity based on field value
  @action
  private onSearch(searchValue: string, fieldKey: string): void {
    switch (fieldKey) {
      case 'nationalities':
      case 'restrictingCountry':
        const countryRequest: IAPIGridRequest = this.getSearchRequest(searchValue, SEARCH_ENTITY_TYPE.COUNTRY);
        this.observeSearch(this.aircraftOperatorRestrictionsStore.getCountries(countryRequest));
        break;
      case 'effectedEntity':
        const request: IAPIGridRequest = this.getSearchRequest(searchValue, this.effectedEntityType);
        this.observeSearch(this.entityOptionsStore.searchEntity(this.effectedEntityType, request, searchValue));
        break;
    }
  }

  @action
  private onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'effectedEntityType':
        this.observeSearch(this.aircraftOperatorSettings.getEffectedEntityTypes());
        break;
      case 'restrictionSource':
        this.observeSearch(this.settingsStore.getRestrictionSources());
        break;
      case 'restrictionType':
        this.observeSearch(this.aircraftOperatorSettings.getAircraftOperatorRestrictionTypes());
        break;
      case 'restrictionSeverity':
        this.observeSearch(this.aircraftOperatorSettings.getRestrictionSeverities());
        break;
      case 'aircraftOperatorRestrictionForms':
        this.observeSearch(
          this.aircraftOperatorSettings.getRestrictionForms().pipe(
            tapWithAction(response => {
              this.restrictionForms = response.map(x => new EntityMapModel({ entityId: x.id, name: x.name }));
            })
          )
        );
        break;
      case 'uwaAllowableServices':
        this.observeSearch(
          this.settingsStore.getUWAAllowableServices().pipe(
            tapWithAction(response => {
              this.uwaAllowableServices = response.map(x => new EntityMapModel({ entityId: x.id, name: x.name }));
            })
          )
        );
        break;
      case 'approvalTypeRequired':
        this.observeSearch(this.aircraftOperatorSettings.getApprovalTypesRequired());
        break;
      case 'uwaAllowableActions':
        this.observeSearch(this.settingsStore.getUWAAllowableActions());
        break;
      case 'enforcementAgency':
        this.observeSearch(this.aircraftOperatorSettings.getEnforcementAgencies());
        break;
      case 'accessLevel':
        this.observeSearch(this.settingsStore.getAccessLevels());
        break;
      case 'sourceType':
        this.observeSearch(this.settingsStore.getSourceTypes());
        break;
    }
  }

  private get headerActions(): ReactNode {
    return (
      <DetailsEditorHeaderSection
        title="Aircraft Operator Restrictions"
        backNavLink={this.backNavLink}
        backNavTitle="Aircraft Operator Restrictions"
        disableActions={this.hasError || UIStore.pageLoading || !this.form.changed}
        isEditMode={this.isEditable}
        hasEditPermission={RestrictionModuleSecurity.isEditable}
        onAction={action => this.onAction(action)}
      />
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <ConfirmNavigate isBlocker={this.form.changed || this.form.touched}>
        <DetailsEditorWrapper
          headerActions={this.headerActions}
          isEditMode={this.isEditable}
          classes={{ container: classes.editorWrapperContainer, headerActionsEditMode: classes.headerActionsEditMode }}
        >
          <ViewInputControlsGroup
            groupInputControls={this.groupInputControls}
            field={fieldKey => this.getField(fieldKey)}
            isEditing={this.isEditable}
            isLoading={this.loader.isLoading}
            onValueChange={(option: IOptionValue, fieldKey: string) => this.onValueChange(option, fieldKey)}
            onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => this.onSearch(searchValue, fieldKey)}
          />
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(UpsertAircraftOperatorRestriction));
export { UpsertAircraftOperatorRestriction as PureUpsertAircraftOperatorRestriction };
