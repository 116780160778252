import React, { ReactNode } from 'react';
import {
  ModelStatusOptions,
  BaseUpsertComponent,
  VIEW_MODE,
  CountryModel,
  StateModel,
  FARTypeModel,
  FIRModel,
  BaseAirportStore,
  AirportModel,
} from '@wings/shared';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { fields } from './Fields';
import { withStyles } from '@material-ui/styles';
import { styles } from './UpsertScheduleRestrictions.styles';
import {
  RestrictionModuleSecurity,
  ScheduleRestrictionEntityModel,
  ScheduleRestrictionsModel,
  ScheduleRestrictionsStore,
  SCHEDULE_RESTRICTIONS_LEVEL,
  SettingsStore,
  updateRestrictionSidebarOptions,
} from '../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { NavigateFunction } from 'react-router';
import { Checkbox, FormLabel } from '@material-ui/core';
import classNames from 'classnames';
import {
  DATE_FORMAT,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  IOptionValue,
  ISelectOption,
  SEARCH_ENTITY_TYPE,
  UIStore,
  Utilities,
  baseEntitySearchFilters,
  tapWithAction,
  withRouter,
  IdNameCodeModel,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { AuditFields, EDITOR_TYPES, ViewInputControlsGroup, IGroupInputControls } from '@wings-shared/form-controls';
import {
  DetailsEditorHeaderSection,
  DetailsEditorWrapper,
  ConfirmNavigate,
  ConfirmDialog,
  SidebarStore,
} from '@wings-shared/layout';

export type LevelEntityType =
  | ScheduleRestrictionEntityModel
  | ISelectOption
  | CountryModel
  | StateModel
  | AirportModel
  | FARTypeModel
  | FIRModel;

interface Props {
  params?: { scheduleRestrictionId: string; viewMode: VIEW_MODE };
  viewMode?: VIEW_MODE;
  scheduleRestrictionsStore?: ScheduleRestrictionsStore;
  settingsStore?: SettingsStore;
  classes?: IClasses;
  navigate: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject('scheduleRestrictionsStore', 'settingsStore', 'sidebarStore')
@observer
class UpsertScheduleRestrictions extends BaseUpsertComponent<Props, ScheduleRestrictionsModel> {
  private readonly backNavLink: string = '/restrictions/schedule-restrictions';
  @observable private scheduleRestriction: ScheduleRestrictionsModel;
  @observable private baseAirportStore: BaseAirportStore = new BaseAirportStore();

  constructor(p: Props) {
    super(p, fields, baseEntitySearchFilters);
    const viewMode = this.props.params?.viewMode;
    this.viewMode = (viewMode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS;
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateRestrictionSidebarOptions('Schedule Restrictions'), 'restrictions');
    this.loadData();
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  private get scheduleRestrictionsStore(): ScheduleRestrictionsStore {
    return this.props.scheduleRestrictionsStore as ScheduleRestrictionsStore;
  }

  private get scheduleRestrictionId(): number | null {
    const scheduleRestrictionId = this.props.params?.scheduleRestrictionId;
    return Utilities.getNumberOrNullValue(scheduleRestrictionId as string);
  }

  /* istanbul ignore next */
  private get arrivalLevel(): SCHEDULE_RESTRICTIONS_LEVEL {
    return this.getField('arrivalLevel').value?.label;
  }

  /* istanbul ignore next */
  private get departureLevel(): SCHEDULE_RESTRICTIONS_LEVEL {
    return this.getField('departureLevel').value?.label;
  }

  /* istanbul ignore next */
  private get overFlightLevel(): SCHEDULE_RESTRICTIONS_LEVEL {
    return this.getField('overFlightLevel').value?.label;
  }

  /* istanbul ignore next */
  private startAdornment(fieldKey: string, disabled: boolean): ReactNode {
    const { labelRoot, checkboxRoot, labelRootDisable } = this.props.classes as IClasses;
    return (
      <div className={classNames({ [labelRoot]: true, [labelRootDisable]: disabled })}>
        <FormLabel>All</FormLabel>
        <Checkbox
          disabled={disabled}
          checked={this.getField(fieldKey).value}
          value={this.getField(fieldKey).value}
          onChange={(e, checked) => {
            this.onValueChange(checked, fieldKey);
            this.getField(fieldKey).sync(e);
          }}
          className={checkboxRoot}
        />
      </div>
    );
  }

  /* istanbul ignore next */
  private get requestModel(): ScheduleRestrictionsModel {
    const modelData: ScheduleRestrictionsModel = this.form.values();
    return new ScheduleRestrictionsModel({
      ...this.scheduleRestriction,
      ...modelData,
      farTypes: modelData.farTypes?.map(entity => this.mapEntity(entity, SCHEDULE_RESTRICTIONS_LEVEL.FAR_TYPE)),
      restrictingEntities: modelData.restrictingEntities?.map(entity =>
        this.mapEntity(entity, SCHEDULE_RESTRICTIONS_LEVEL.COUNTRY)
      ),
      arrivalLevelEntities: modelData.arrivalLevelEntities?.map(entity => this.mapEntity(entity, this.arrivalLevel)),
      departureLevelEntities: modelData.departureLevelEntities?.map(entity =>
        this.mapEntity(entity, this.departureLevel)
      ),
      overFlightLevelEntities: modelData.overFlightLevelEntities?.map(entity =>
        this.mapEntity(entity, this.overFlightLevel)
      ),
      departureEntityExceptions: modelData.departureEntityExceptions?.map(entity =>
        this.mapEntity(entity, this.departureLevel)
      ),
      arrivalEntityExceptions: modelData.arrivalEntityExceptions?.map(entity =>
        this.mapEntity(entity, this.arrivalLevel)
      ),
      overFlightEntityExceptions: modelData.overFlightEntityExceptions?.map(entity =>
        this.mapEntity(entity, this.overFlightLevel)
      ),
    });
  }

  /* istanbul ignore next */
  // Get Level Entities based on related level field
  private getEntitiesOptions(fieldLevel: SCHEDULE_RESTRICTIONS_LEVEL): LevelEntityType[] {
    switch (fieldLevel) {
      case SCHEDULE_RESTRICTIONS_LEVEL.STATE:
        return this.scheduleRestrictionsStore.states;
      case SCHEDULE_RESTRICTIONS_LEVEL.AIRPORT:
        return this.baseAirportStore.wingsAirports;
      case SCHEDULE_RESTRICTIONS_LEVEL.COUNTRY:
        return this.scheduleRestrictionsStore.countries;
      case SCHEDULE_RESTRICTIONS_LEVEL.FIR:
        return this.scheduleRestrictionsStore.firs;
      default:
        return [];
    }
  }

  /* istanbul ignore next */
  // Get Level Entities based on related level field
  private getEntityLabel(entity: LevelEntityType, fieldKey: SCHEDULE_RESTRICTIONS_LEVEL): string {
    if ((entity as ScheduleRestrictionEntityModel).entityId) {
      return entity.label;
    }
    switch (fieldKey) {
      case SCHEDULE_RESTRICTIONS_LEVEL.STATE:
        return (entity as StateModel).entityCode;
      case SCHEDULE_RESTRICTIONS_LEVEL.AIRPORT:
        return (entity as AirportModel).icaoOrUwaCode || (entity as AirportModel).displayCode;
      case SCHEDULE_RESTRICTIONS_LEVEL.COUNTRY:
        return (entity as CountryModel).isO2Code;
      case SCHEDULE_RESTRICTIONS_LEVEL.FAR_TYPE:
        return (entity as FARTypeModel).cappsCode;
      case SCHEDULE_RESTRICTIONS_LEVEL.FIR:
        return (entity as FIRModel).code;
      default:
        return '';
    }
  }

  /* istanbul ignore next */
  // get selected option for AUTO Complete
  private getOptionSelected(currentOption: ISelectOption, values: ISelectOption | ISelectOption[]): boolean {
    if (!values) {
      return false;
    }
    if (Array.isArray(values)) {
      return values.map(options => options.value).includes(currentOption.value);
    }
    const { id, entityId } = values as ScheduleRestrictionEntityModel;
    return Utilities.isEqual((currentOption as IdNameCodeModel)?.id, entityId || id);
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    const { classes } = this.props as Required<Props>;
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'restrictionType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.restrictionTypes,
          },
          {
            fieldKey: 'restrictingEntities',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.scheduleRestrictionsStore.countries,
            isDisabled: !Boolean(this.getField('restrictionType').value?.label),
            getChipLabel: option => this.getEntityLabel(option, SCHEDULE_RESTRICTIONS_LEVEL.COUNTRY),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'departureLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.scheduleDepartureLevels,
          },
          {
            fieldKey: 'departureLevelEntities',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.departureLevel),
            isDisabled: !Boolean(this.departureLevel) || this.getField('isAllDeparture').value,
            getChipLabel: option => this.getEntityLabel(option, this.departureLevel),
            getOptionSelected: (option, value) => this.getOptionSelected(option, value),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
            startAdornment: this.startAdornment('isAllDeparture', !Boolean(this.departureLevel)),
            isAllOptionsSelected: this.getField('isAllDeparture').value,
          },
          {
            fieldKey: 'departureEntityExceptions',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.departureLevel),
            isDisabled: !this.getField('isAllDeparture').value,
            getChipLabel: option => this.getEntityLabel(option, this.departureLevel),
            getOptionSelected: (option, value) => this.getOptionSelected(option, value),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'arrivalLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.arrivalLevels,
          },
          {
            fieldKey: 'arrivalLevelEntities',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.arrivalLevel),
            isDisabled: !Boolean(this.arrivalLevel) || this.getField('isAllArrival').value,
            getChipLabel: option => this.getEntityLabel(option, this.arrivalLevel),
            getOptionSelected: (option, value) => this.getOptionSelected(option, value),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
            startAdornment: this.startAdornment('isAllArrival', !Boolean(this.arrivalLevel)),
            isAllOptionsSelected: this.getField('isAllArrival').value,
          },
          {
            fieldKey: 'arrivalEntityExceptions',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.arrivalLevel),
            isDisabled: !this.getField('isAllArrival').value,
            getChipLabel: option => this.getEntityLabel(option, this.arrivalLevel),
            getOptionSelected: (option, value) => this.getOptionSelected(option, value),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'overFlightLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.overflightLevels,
          },
          {
            fieldKey: 'overFlightLevelEntities',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.overFlightLevel),
            isDisabled: !Boolean(this.overFlightLevel) || this.getField('isAllOverFlight').value,
            getChipLabel: option => this.getEntityLabel(option, this.overFlightLevel),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
            startAdornment: this.startAdornment('isAllOverFlight', !Boolean(this.overFlightLevel)),
            isAllOptionsSelected: this.getField('isAllOverFlight').value,
          },
          {
            fieldKey: 'overFlightEntityExceptions',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            showChipTooltip: true,
            isLoading: this.loader.isLoading,
            options: this.getEntitiesOptions(this.overFlightLevel),
            isDisabled: !Boolean(this.getField('isAllOverFlight').value),
            getChipLabel: option => this.getEntityLabel(option, this.overFlightLevel),
            getOptionSelected: (option, value) => this.getOptionSelected(option, value),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'farTypes',
            multiple: true,
            showChipTooltip: true,
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.farTypes,
            getChipLabel: option => this.getEntityLabel(option, SCHEDULE_RESTRICTIONS_LEVEL.FAR_TYPE),
            getChipTooltip: option => (option as ScheduleRestrictionEntityModel)?.entityName,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'startDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            maxDate: this.getField('endDate').value,
          },
          {
            fieldKey: 'endDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
            minDate: this.getField('startDate').value,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'validatedDate',
            type: EDITOR_TYPES.DATE,
            dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
          },
          {
            fieldKey: 'validatedBy',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: '',
        className: classes.restrictionsGroup,
        inputControlClassName: classes.validationNotes,
        inputControls: [
          {
            fieldKey: 'validationNotes',
            type: EDITOR_TYPES.TEXT_FIELD,
            multiline: true,
            rows: 3,
          },
        ],
      },
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'accessLevel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.accessLevels,
          },
          {
            fieldKey: 'status',
            type: EDITOR_TYPES.DROPDOWN,
            options: ModelStatusOptions,
          },
          {
            fieldKey: 'sourceType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.sourceTypes,
          },
        ],
      },
    ];
  }

  /* istanbul ignore next */
  private loadData(): void {
    UIStore.setPageLoader(true);
    forkJoin([
      this.loadScheduleRestriction(),
      this.settingsStore.getRestrictionTypes(),
      this.settingsStore.getScheduleDepartureLevels(),
      this.settingsStore.getArrivalLevels(),
      this.settingsStore.getOverflightLevels(),
      this.loadBaseOptions(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private loadBaseOptions(): Observable<[SettingsTypeModel[], SettingsTypeModel[], FARTypeModel[]]> {
    return forkJoin([
      this.settingsStore.getAccessLevels(),
      this.settingsStore.getSourceTypes(),
      this.settingsStore.getFarTypes(),
    ]);
  }

  /* istanbul ignore next */
  private loadScheduleRestriction(): Observable<IAPIPageResponse<ScheduleRestrictionsModel>> | Observable<null> {
    if (!this.scheduleRestrictionId) {
      return of(null);
    }
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        { propertyName: 'ScheduleRestrictionId', propertyValue: this.scheduleRestrictionId },
      ]),
    };

    return this.scheduleRestrictionsStore.getScheduleRestrictions(request).pipe(
      tapWithAction((response: IAPIPageResponse) => {
        this.scheduleRestriction = response.results[0];
        this.setFormValues(this.scheduleRestriction);
        this.setRestrictionsRules();
      })
    );
  }

  /* istanbul ignore next */
  @action
  private upsertScheduleRestriction(): void {
    UIStore.setPageLoader(true);
    this.scheduleRestrictionsStore
      .upsertScheduleRestrictions(this.requestModel.serialize())
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (scheduleRestriction: ScheduleRestrictionsModel) => {
          const _requestId = this.scheduleRestriction?.id;
          this.scheduleRestriction = scheduleRestriction;
          this.form.reset();
          this.form.set(this.scheduleRestriction);
          const viewMode = this.props.params?.viewMode.toUpperCase();
          // if new schedule restriction then redirect to edit screen
          if (!Boolean(_requestId) && viewMode === VIEW_MODE.NEW) {
            this.props.navigate(`${this.backNavLink}/${scheduleRestriction.id}/edit`);
            return;
          }
          if (viewMode === VIEW_MODE.DETAILS) {
            this.setViewMode(VIEW_MODE.DETAILS);
          }
        },
        error: error => this.showAlert(error.message, 'upsertScheduleRestriction'),
      });
  }

  // map Different Entities models to the ScheduleRestrictionEntityModel
  /* istanbul ignore next */
  private mapEntity(
    entity: ScheduleRestrictionEntityModel,
    entityType: SCHEDULE_RESTRICTIONS_LEVEL
  ): ScheduleRestrictionEntityModel {
    if (entity.entityId) {
      return entity;
    }
    return new ScheduleRestrictionEntityModel({
      entityId: entity.id,
      code: this.getEntityLabel(entity, entityType), // get entity code based on the entity type
    });
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.SAVE:
        this.upsertScheduleRestriction();
        break;
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this.confirmClose();
        break;
    }
  }

  private confirmClose(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode !== VIEW_MODE.DETAILS) {
      this.props.navigate(this.backNavLink, this.noBlocker);
      return;
    }
    if (!(this.form.touched || this.form.changed)) {
      this.onCancel();
      return;
    }
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Cancellation"
        message="Leaving Edit Mode will cause your changes to be lost. Are you sure you want to exit Edit Mode?"
        yesButton="Yes"
        onNoClick={() => {
          ModalStore.close();
        }}
        onYesClick={() => {
          this.onCancel();
          ModalStore.close();
        }}
      />
    );
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params?.viewMode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.form.reset();
      this.setFormValues(this.scheduleRestriction);
      return;
    }
    this.props.navigate(this.backNavLink);
  }

  /* istanbul ignore next */
  @action
  private clearEntityOptions(): void {
    this.scheduleRestrictionsStore.states = [];
    this.baseAirportStore.wingsAirports = [];
    this.scheduleRestrictionsStore.countries = [];
    this.scheduleRestrictionsStore.firs = [];
  }

  /* istanbul ignore next */
  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'restrictionType':
        this.getField('restrictingEntities').set([]);
        this.setRestrictionsRules();
        break;
      case 'departureLevel':
        if (!value) {
          this.getField('isAllDeparture').set(false);
        }
        this.getField('departureLevelEntities').set([]);
        this.getField('departureEntityExceptions').set([]);
        break;
      case 'arrivalLevel':
        if (!value) {
          this.getField('isAllArrival').set(false);
        }
        this.getField('arrivalLevelEntities').set([]);
        this.getField('arrivalEntityExceptions').set([]);
        break;
      case 'overFlightLevel':
        if (!value) {
          this.getField('isAllOverFlight').set(false);
        }
        this.getField('overFlightLevelEntities').set([]);
        this.getField('overFlightEntityExceptions').set([]);
        break;
      case 'isAllDeparture':
        this.getField('departureLevelEntities').set([]);
        this.getField('departureEntityExceptions').set([]);
        this.clearEntityOptions();
        break;
      case 'isAllArrival':
        this.getField('arrivalLevelEntities').set([]);
        this.getField('arrivalEntityExceptions').set([]);
        this.clearEntityOptions();
        break;
      case 'isAllOverFlight':
        this.getField('overFlightLevelEntities').set([]);
        this.getField('overFlightEntityExceptions').set([]);
        this.clearEntityOptions();
        break;
    }
  }

  /* istanbul ignore next */
  private setRestrictionsRules(): void {
    const restrictionType = this.getField('restrictionType').value?.label;
    const isLanding = Utilities.isEqual(restrictionType, 'Landing');
    const isOverflight = Utilities.isEqual(restrictionType, 'Overflight');
    this.setFormRules('arrivalLevel', isLanding, fields.arrivalLevel.label);
    this.setFormRules('overFlightLevel', isOverflight, fields.overFlightLevel.label);
  }

  // Search Entity based on field value
  @action
  private onSearch(searchValue: string, fieldKey: string): void {
    // Related Field field from fields
    const relatedField = fields[fieldKey]?.relatedField;
    const fieldLevel: string = relatedField ? this.getField(relatedField).value?.label : fieldKey;
    const request: IAPIGridRequest = this.getSearchRequest(searchValue, fieldLevel as SEARCH_ENTITY_TYPE);

    switch (fieldLevel) {
      case SEARCH_ENTITY_TYPE.STATE:
        this.observeSearch(this.scheduleRestrictionsStore.getStates(request, true));
        break;
      case SEARCH_ENTITY_TYPE.AIRPORT:
        this.observeSearch(this.baseAirportStore.searchWingsAirports(searchValue, true));
        break;
      case SEARCH_ENTITY_TYPE.COUNTRY:
      case 'restrictingEntities':
        const countryRequest: IAPIGridRequest = this.getSearchRequest(searchValue, SEARCH_ENTITY_TYPE.COUNTRY);
        this.observeSearch(this.scheduleRestrictionsStore.getCountries(countryRequest, true));
        break;
      case SEARCH_ENTITY_TYPE.FAR_TYPE:
        this.observeSearch(this.settingsStore.getFarTypes());
        break;
      case SEARCH_ENTITY_TYPE.FIR:
        this.observeSearch(this.scheduleRestrictionsStore.getFIRs(request));
        break;
    }
  }

  private get headerActions(): ReactNode {
    return (
      <DetailsEditorHeaderSection
        title="Schedule Restrictions"
        backNavLink={this.backNavLink}
        backNavTitle="Schedule Restrictions"
        disableActions={this.form.hasError || UIStore.pageLoading || !this.form.changed}
        isEditMode={this.isEditable}
        hasEditPermission={RestrictionModuleSecurity.isEditable}
        onAction={action => this.onAction(action)}
      />
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <ConfirmNavigate isBlocker={this.form.changed || this.form.touched}>
        <DetailsEditorWrapper
          headerActions={this.headerActions}
          isEditMode={this.isEditable}
          classes={{ container: classes.editorWrapperContainer, headerActionsEditMode: classes.headerActionsEditMode }}
        >
          <ViewInputControlsGroup
            groupInputControls={this.groupInputControls}
            field={fieldKey => this.getField(fieldKey)}
            isEditing={this.isEditable}
            onValueChange={(option: IOptionValue, fieldKey: string) => this.onValueChange(option, fieldKey)}
            onSearch={(searchValue: string, fieldKey: string) => this.onSearch(searchValue, fieldKey)}
          />
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    );
  }
}

export default withRouter(withStyles(styles)(UpsertScheduleRestrictions));
export { UpsertScheduleRestrictions as PureUpsertScheduleRestrictions };
