import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core';
import {
  VIEW_MODE,
  BaseUpsertComponent,
  CountryModel,
  StateModel,
  ModelStatusOptions,
  AirportModel,
} from '@wings/shared';
import { AuthStore } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { fields } from './Fields';
import { styles } from './HealthVendorEditor.style';
import {
  AUTHORIZATION_LEVEL,
  HealthAuthStore,
  SettingsStore,
  HealthVendorStore,
  HealthVendorModel,
  HealthVendorContactModel,
  updateRestrictionSidebarOptions,
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigateFunction } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { AlertStore } from '@uvgo-shared/alert';
import HealthVendorTabs from '../HealthVendorTabs/HealthVendorTabs';
import { forkJoin, Observable } from 'rxjs';
import {
  ENTITY_STATE,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  IOptionValue,
  UIStore,
  Utilities,
  withRouter,
  IdNameCodeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import {
  AuditFields,
  EDITOR_TYPES,
  ViewInputControl,
  IGroupInputControls,
  IViewInputControl,
} from '@wings-shared/form-controls';
import {
  CustomLinkButton,
  DetailsEditorWrapper,
  EditSaveButtons,
  Collapsable,
  SidebarStore,
} from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  healthAuthStore?: HealthAuthStore;
  settingsStore?: SettingsStore;
  healthVendorStore?: HealthVendorStore;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject('healthVendorStore', 'healthAuthStore', 'settingsStore','sidebarStore')
@observer
class HealthVendorEditor extends BaseUpsertComponent<Props, HealthVendorModel> {
  @observable private healthVendor: HealthVendorModel = new HealthVendorModel();
  @observable private healthVendorDetails: HealthVendorModel = new HealthVendorModel();
  @observable private isContactEditing: boolean = false;

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params?.mode?.toUpperCase() || '';
    this.viewMode = VIEW_MODE[mode] || VIEW_MODE.DETAILS;
  }

  public get hasError(): boolean {
    return this.form.hasError || this.isContactEditing;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateRestrictionSidebarOptions('Health Vendor'), 'restrictions');
    if (!this.healthVendorId) {
      this.setFormValues(this.healthVendor);
      return;
    }
    UIStore.setPageLoader(true);
    forkJoin([ this.loadHealthVendorById(), this.healthVendorStore.getHealthVendors() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ response ]) => {
        this.healthVendor = new HealthVendorModel(response.results[0]);
        this.healthVendorDetails = new HealthVendorModel({ ...this.healthVendor });
        const { authorizationLevel } = this.healthVendor;
        this.setFormRules('vendorLevelEntity', Boolean(authorizationLevel?.id), 'Vendor Level Entity');
        this.setFormValues(this.healthVendor);
      });
  }

  /* istanbul ignore next */
  private get filterCollection(): IAPIGridRequest {
    const healthAuthFilter = { propertyName: 'HealthVendorId', propertyValue: this.healthVendorId };
    return {
      filterCollection: JSON.stringify([ healthAuthFilter ]),
    };
  }

  /* istanbul ignore next */
  private loadHealthVendorById(): Observable<IAPIPageResponse<HealthVendorModel>> {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      ...this.filterCollection,
    };
    return this.healthVendorStore.getHealthVendorById(request).pipe(takeUntil(this.destroy$));
  }

  private isVendorLevelEntityExists(old: HealthVendorModel, curr: HealthVendorModel): boolean {
    if (Utilities.isEqual(this.authorizationLevel, AUTHORIZATION_LEVEL.AIRPORT)) {
      return Utilities.isEqual(old.vendorLevelEntity?.label, curr.vendorLevelEntity?.label);
    }
    return Utilities.isEqual(old.vendorLevelEntity?.id, curr.vendorLevelEntity?.id);
  }

  private isAlreadyExists(healthVendor: HealthVendorModel): boolean {
    const { healthVendors } = this.healthVendorStore;
    return healthVendors.some(
      x =>
        !Utilities.isEqual(x.id, healthVendor.id) &&
        Utilities.isEqual(x.authorizationLevel?.id, healthVendor.authorizationLevel?.id) &&
        Utilities.isEqual(x.name, healthVendor.name) &&
        this.isVendorLevelEntityExists(x, healthVendor)
    );
  }

  /* istanbul ignore next */
  private upsertHealthVendor(): void {
    const healthVendor: HealthVendorModel = this.getUpdatedModel();
    const message = 'A record already exists with the combination selected. Please edit existing record.';
    if (this.isAlreadyExists(healthVendor)) {
      this.showAlert(message, 'healthVendorid');
      return;
    }
    UIStore.setPageLoader(true);
    this.healthVendorStore
      .upsertHealthVendor(healthVendor)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => this.navigateToHealthVendor(),
        error: error => AlertStore.critical(error.message),
      });
  }

  private getUpdatedModel(): HealthVendorModel {
    const formValues: HealthVendorModel = this.form.values();
    const updatedModel = new HealthVendorModel({
      ...this.healthVendor,
      ...formValues,
      vendorLevelEntityId: formValues.vendorLevelEntity?.id,
      vendorLevelEntityCode: this.getVendorLevelEntityCode(formValues.vendorLevelEntity),
    });
    return updatedModel;
  }

  private getVendorLevelEntityCode(
    vendorLevelEntity: IdNameCodeModel | CountryModel | StateModel | AirportModel
  ): string {
    let code = '';
    switch (this.authorizationLevel) {
      case AUTHORIZATION_LEVEL.COUNTRY:
        code = (vendorLevelEntity as CountryModel)?.isO2Code;
        break;
      case AUTHORIZATION_LEVEL.AIRPORT:
        code = (vendorLevelEntity as AirportModel)?.label;
        break;
      case AUTHORIZATION_LEVEL.STATE:
        code = (vendorLevelEntity as StateModel)?.isoCode;
        break;
    }
    return code || (vendorLevelEntity as IdNameCodeModel)?.code;
  }

  @action
  protected onCancel(model: HealthVendorModel): void {
    const viewMode = this.props.params?.mode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.healthVendor = new HealthVendorModel({ ...this.healthVendorDetails });
      this.form.reset();
      this.setFormValues(model);
      return;
    }
    this.navigateToHealthVendor();
  }

  private onAction(action: GRID_ACTIONS) {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.viewMode = VIEW_MODE.EDIT;
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertHealthVendor();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel(this.healthVendorDetails);
        break;
    }
  }

  /* istanbul ignore next */
  private navigateToHealthVendor(): void {
    const props = this.props as Required<Props>;
    props.navigate('/restrictions/health-vendor');
  }

  private get healthVendorId(): number {
    const id = this.props.params?.id;
    return Number(id) || 0;
  }

  protected get isEditable(): boolean {
    return this.viewMode === VIEW_MODE.EDIT || this.viewMode === VIEW_MODE.NEW;
  }

  private get healthAuthStore(): HealthAuthStore {
    return this.props.healthAuthStore as HealthAuthStore;
  }

  private get healthVendorStore(): HealthVendorStore {
    return this.props.healthVendorStore as HealthVendorStore;
  }

  private get authorizationLevel(): string {
    return this.getField('authorizationLevel').value?.label;
  }

  private get vendorLevelEntity(): string {
    return this.getField('vendorLevelEntity').value?.label;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'General',
      inputControls: [
        {
          fieldKey: 'name',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'authorizationLevel',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.healthAuthStore.authorizationLevels,
          isLoading: true,
        },
        {
          fieldKey: 'vendorLevelEntity',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.vendorLevelEntityOptions,
          isLoading: true,
          isDisabled: !Boolean(this.authorizationLevel),
        },
        {
          fieldKey: 'accessLevel',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.accessLevels,
          isLoading: true,
        },
        {
          fieldKey: 'sourceType',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.sourceTypes,
          isLoading: true,
        },
        {
          fieldKey: 'status',
          type: EDITOR_TYPES.DROPDOWN,
          options: ModelStatusOptions,
        },
      ],
    };
  }

  @action
  protected onValueChange(value: IOptionValue | IOptionValue[], fieldKey: string): void {
    if (Utilities.isEqual('authorizationLevel', fieldKey)) {
      this.getField('vendorLevelEntity').set('');
      this.setFormRules('vendorLevelEntity', Boolean(value), 'Vendor Level Entity');
      this.getField(fieldKey).set(value);
      return;
    }
    this.getField(fieldKey).set(value);
  }

  private onSearch(value: string, fieldKey: string): void {
    if (Utilities.isEqual(fieldKey, 'vendorLevelEntity'))
      switch (this.authorizationLevel) {
        case AUTHORIZATION_LEVEL.AIRPORT:
          this.loadAirports(value);
          break;
        case AUTHORIZATION_LEVEL.STATE:
          this.loadStates(value);
          break;
        case AUTHORIZATION_LEVEL.COUNTRY:
          this.loadCountries(value);
          break;
      }
  }

  /* istanbul ignore next */
  public loadAuthorizationLevels(): void {
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .getAuthorizationLevels()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  public loadCountries(propertyValue?: string): void {
    UIStore.setPageLoader(true);
    const searchCollection = [
      {
        propertyName: 'CommonName',
        operator: 'and',
        propertyValue,
      },
      {
        propertyName: 'ISO2Code',
        operator: 'or',
        propertyValue,
      },
    ];
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      searchCollection: propertyValue ? JSON.stringify(searchCollection) : null,
    };
    this.healthAuthStore
      .getCountries(request, true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  public loadStates(propertyValue?: string): void {
    UIStore.setPageLoader(true);
    const searchCollection = [
      {
        propertyName: 'CommonName',
        operator: 'and',
        propertyValue,
      },
      {
        propertyName: 'ISOCode',
        operator: 'or',
        propertyValue,
      },
    ];
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      searchCollection: propertyValue ? JSON.stringify(searchCollection) : null,
    };
    this.healthAuthStore
      .getStates(request, true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  public loadAirports(propertyValue?: string): void {
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .getWingsAirport(propertyValue || '')
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  public loadAccessLevels(): void {
    UIStore.setPageLoader(true);
    this.settingsStore
      .getAccessLevels()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  public loadSourceTypes(): void {
    UIStore.setPageLoader(true);
    this.settingsStore
      .getSourceTypes()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'vendorLevelEntity':
        this.loadVendorLevelEntityOptions();
        break;
      case 'authorizationLevel':
        this.loadAuthorizationLevels();
        break;
      case 'sourceType':
        this.loadSourceTypes();
        break;
      case 'accessLevel':
        this.loadAccessLevels();
        break;
    }
  }

  /* istanbul ignore next */
  private loadVendorLevelEntityOptions(): void {
    switch (this.authorizationLevel) {
      case AUTHORIZATION_LEVEL.AIRPORT:
        this.loadAirports();
        break;
      case AUTHORIZATION_LEVEL.STATE:
        this.loadStates();
        break;
      default:
        this.loadCountries();
    }
  }

  private get vendorLevelEntityOptions(): IdNameCodeModel[] | CountryModel[] | StateModel[] | AirportModel[] {
    switch (this.authorizationLevel) {
      case AUTHORIZATION_LEVEL.STATE:
        return this.healthAuthStore.states;
      case AUTHORIZATION_LEVEL.AIRPORT:
        return this.healthAuthStore.wingsAirports;
      case AUTHORIZATION_LEVEL.COUNTRY:
        return this.healthAuthStore.countries;
      default:
        return [];
    }
  }

  private get title(): string {
    const { name } = this.form.values();
    return [ name || 'Name', this.authorizationLevel || 'Vendor Level', this.vendorLevelEntity || 'Entity' ].join('-');
  }

  private get headerActions(): ReactNode {
    return (
      <>
        {!this.isEditable && (
          <CustomLinkButton to="/restrictions/health-vendor" title="Health Vendor" startIcon={<ArrowBack />} />
        )}
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={AuthStore.user?.isAdminUser || AuthStore.user?.isQRGAdmin}
          isEditMode={this.isEditable}
          isEditing={this.isContactEditing}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  private getUpdatedContacts(contact: HealthVendorContactModel, removeContact: boolean): HealthVendorContactModel[] {
    const { healthVendorContacts } = this.healthVendor;
    if (removeContact) {
      return healthVendorContacts.filter(a => !a.isSameData(contact));
    }
    if (!Boolean(contact.tempId || contact.id)) {
      contact.tempId = Utilities.getTempId(true);
      contact.entityState = ENTITY_STATE.MODIFIED;
      return [ ...healthVendorContacts, ...[ contact ] ];
    }
    const existingIndex: number = healthVendorContacts.findIndex(x => x.isSameData(contact));
    healthVendorContacts[existingIndex] = contact;
    return healthVendorContacts;
  }

  private updateHealthAuthContacts(contact: HealthVendorContactModel, removeContact?: boolean): void {
    this.healthVendor = new HealthVendorModel({
      ...this.healthVendor,
      healthVendorContacts: this.getUpdatedContacts(contact, removeContact as boolean),
    });
  }

  private get tabsLayout(): ReactNode {
    return (
      <HealthVendorTabs
        isEditable={this.isEditable}
        contacts={this.healthVendor.healthVendorContacts}
        onUpdate={(contact, removeContact) => this.updateHealthAuthContacts(contact, removeContact)}
        onContactEditing={isEditing => (this.isContactEditing = isEditing)}
        getField={(fieldKey: string) => this.getField(fieldKey)}
        onChange={(value, fieldKey) => this.onValueChange(value, fieldKey)}
      />
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.flexRow}>
          <Collapsable title={this.title}>
            <div className={classes.flexWrap}>
              {this.groupInputControls.inputControls
                .filter(inputControl => !inputControl.isHidden)
                .map((inputControl: IViewInputControl, index: number) => (
                  <ViewInputControl
                    {...inputControl}
                    key={index}
                    customErrorMessage={inputControl.customErrorMessage}
                    field={this.getField(inputControl.fieldKey || '')}
                    isEditable={this.isEditable}
                    isExists={inputControl.isExists}
                    classes={{
                      flexRow: classes.inputControl,
                    }}
                    onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                    onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                    onSearch={(value: string, fieldKey: string) => this.onSearch(value, fieldKey)}
                  />
                ))}
            </div>
          </Collapsable>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
          {this.tabsLayout}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(HealthVendorEditor));
export { HealthVendorEditor as PureHealthVendorEditor };
