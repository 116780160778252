import { createStyles, Theme,makeStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    editorWrapperContainer: {
      overflow: 'auto',
    },
    headerActionsEditMode: {
      justifyContent: 'space-between',
    },
    summary: {
      flexBasis: '100%',
    },
  });
export const useStyles = makeStyles({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  headerActionsEditMode: {
    justifyContent: 'space-between',
  },
  summary: {
    flexBasis: '100%',
  },
});