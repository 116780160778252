import React, { ReactNode } from 'react';
import { AuditHistory, baseApiPath, VIEW_MODE } from '@wings/shared';
import {
  AgGridActions,
  AgGridChipView,
  AgGridGroupHeader,
  AgGridStatusBadge,
  BaseGrid,
  CustomAgGridReact,
} from '@wings-shared/custom-ag-grid';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
  FilterModifiedEvent
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  AircraftOperatorRestrictionsStore,
  AircraftOperatorRestrictionsModel,
  AIRCRAFT_OPERATOR_RESTRICTIONS,
  RestrictionModuleSecurity,
  SettingsStore,
  RESTRICTION_AUDIT_MODULES,
  updateRestrictionSidebarOptions,
} from '../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { gridFilters } from './fields';
import { action } from 'mobx';
import {
  DATE_FORMAT,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  UIStore,
  Utilities,
  ViewPermission,
  SearchStore,
  IdNameCodeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IGridSortFilter,
} from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';

interface Props {
  viewMode?: VIEW_MODE;
  aircraftOperatorRestrictionsStore?: AircraftOperatorRestrictionsStore;
  settingsStore?: SettingsStore;
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
}

const filterSetup: IBaseGridFilterSetup<AIRCRAFT_OPERATOR_RESTRICTIONS> = {
  defaultPlaceHolder: 'Search by Name Restriction Type',
  apiFilterDictionary: gridFilters,
  defaultFilterType: AIRCRAFT_OPERATOR_RESTRICTIONS.EFFECTED_ENTITY_TYPE,
  filterTypesOptions: Object.values(AIRCRAFT_OPERATOR_RESTRICTIONS),
};

@inject('aircraftOperatorRestrictionsStore', 'settingsStore', 'sidebarStore')
@observer
class AircraftOperatorRestriction extends BaseGrid<
  Props,
  AircraftOperatorRestrictionsModel,
  AIRCRAFT_OPERATOR_RESTRICTIONS
> {
  constructor(props) {
    super(props, filterSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(
      updateRestrictionSidebarOptions('Aircraft Operator Restrictions'),
      'restrictions'
    );
    this.loadAircraftOperatorRestriction();
    this.onAdvanceSearch$().subscribe(() => this.loadAircraftOperatorRestriction());
    this.setMiniFilterTextDebounce();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private get aircraftOperatorRestrictionsStore(): AircraftOperatorRestrictionsStore {
    return this.props.aircraftOperatorRestrictionsStore as AircraftOperatorRestrictionsStore;
  }

  /* istanbul ignore next */
  @action
  private loadAircraftOperatorRestriction(pageRequest?: IAPIGridRequest): void {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: this.pagination.pageSize,
      ...pageRequest,
      ...this._searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };
    if (this.searchValue) {
      const result = JSON.parse(this._searchFilters?.searchCollection as string)[0];
      if (this.selectedOption === AIRCRAFT_OPERATOR_RESTRICTIONS.EFFECTED_ENTITY) {
        request.searchCollection = JSON.stringify(
          [ result ].concat(Utilities.getFilter('EffectedEntity.Code', this.searchValue as string, 'or'))
        );
      }
      if (this.selectedOption === AIRCRAFT_OPERATOR_RESTRICTIONS.RESTRICTING_COUNTRY) {
        request.searchCollection = JSON.stringify(
          [ result ].concat(Utilities.getFilter('RestrictingCountry.Code', this.searchValue as string, 'or'))
        );
      }
    }

    UIStore.setPageLoader(true);
    this.aircraftOperatorRestrictionsStore
      .getAircraftOperatorRestrictions(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: IAPIPageResponse) => {
        this.pagination = new GridPagination({ ...response });
        this.data = response.results;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        this.gridAdvancedSearchFilterApplied();
        if (searchValue) {
          this._setSelectedOption(
            (selectedOption as AIRCRAFT_OPERATOR_RESTRICTIONS) || AIRCRAFT_OPERATOR_RESTRICTIONS.EFFECTED_ENTITY_TYPE
          );
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (this.searchValue) {
      const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
      SearchStore.setclientSearchValue(clientSearchValue as any);
    }
    if (gridAction === GRID_ACTIONS.AUDIT) {
      const model: AircraftOperatorRestrictionsModel = this._getTableItem(rowIndex);
      ModalStore.open(
        <AuditHistory
          title={model.effectedEntity.label}
          entityId={model.id}
          entityType={RESTRICTION_AUDIT_MODULES.AIRCRAFT_OPERATOR_RESTRICTION}
          baseUrl={baseApiPath.restrictions}
        />
      );
    }
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Effected Entity Type',
      field: 'effectedEntityType',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'effectedEntityType', 2) },
    },
    {
      headerName: 'Effected Entity',
      field: 'effectedEntity',
      comparator: (current: IdNameCodeModel, next: IdNameCodeModel) =>
        Utilities.customComparator(current, next, 'label'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'effectedEntity', 2) },
      filterValueGetter: ({ data }: ValueGetterParams) => data.effectedEntity.label,
    },
    {
      headerName: 'Nationalities',
      field: 'nationalities',
      valueFormatter: ({ value }) => value?.isO2Code || '',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        chipLabelField: 'isO2Code',
        tooltipField: 'commonName',
      },
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'nationalities', 2) },
      comparator: (current, next) => Utilities.customComparator(current, next, 'isO2Code'),
    },
    {
      headerName: 'Restriction Type',
      field: 'restrictionType',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'restrictionType', 2) },
    },
    {
      headerName: 'Restricting Country',
      field: 'restrictingCountry',
      comparator: (current: IdNameCodeModel, next: IdNameCodeModel) =>
        Utilities.customComparator(current, next, 'label'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'restrictingCountry', 2) },
      filterValueGetter: ({ data }: ValueGetterParams) => data.restrictingCountry.label,
    },
    {
      headerName: 'Restriction Severity',
      field: 'restrictionSeverity',
      cellRenderer: 'statusRenderer',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'restrictionSeverity', 2) },
    },
    {
      headerName: 'Approval Type Required',
      field: 'approvalTypeRequired',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'approvalTypeRequired', 2) },
    },
    {
      headerName: 'UWA Allowable Actions',
      field: 'uwaAllowableActions',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: { ...this.nameSearchFilterParams('contains', 'uwaAllowableActions', 2) },
    },
    {
      headerName: 'other details',
      groupId: 'otherDetails',
      suppressMenu: true,
      children: [
        {
          headerName: 'Start Date',
          field: 'startDate',
          headerComponent: 'customHeader',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          columnGroupShow: 'open',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
      ],
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      filter: false,
      sortable: false,
      suppressSizeToFit: true,
      suppressMenu: true,
      minWidth: 120,
      maxWidth: 150,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      gridActionProps: {
        isActionMenu: true,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !RestrictionModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `${node.data.id}/${VIEW_MODE.EDIT.toLocaleLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.VIEW,
            to: node => `${node.data.id}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`,
          },
          {
            title: 'Audit',
            action: GRID_ACTIONS.AUDIT,
          },
        ],
      },
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        cellRendererParams: {
          ...baseOptions.defaultColDef?.cellRendererParams,
          chipLabelField: 'code',
        },
      },
      pagination: false,
      isExternalFilterPresent: () => false,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        agGridChipView: AgGridChipView,
        customHeader: AgGridGroupHeader,
        statusRenderer: AgGridStatusBadge,
      },
      onFilterChanged: () =>  this.gridAdvancedSearchFilters.length === 0 && this.loadAircraftOperatorRestriction(),
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadAircraftOperatorRestriction();
      },
      onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={RestrictionModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="new"
          title="Add Aircraft Operator Restriction"
        />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={option => this._setSelectedOption(option as AIRCRAFT_OPERATOR_RESTRICTIONS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          disablePagination={this.isRowEditing}
          onPaginationChange={request => this.loadAircraftOperatorRestriction(request)}
        />
      </>
    );
  }
}

export default AircraftOperatorRestriction;
