import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ICellEditorParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { HealthAuthStore } from '../../../Shared';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  UIStore,
  Utilities,
  NAME_TYPE_FILTERS,
  IdNameCodeModel,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';

interface Props {
  healthAuthStore?: HealthAuthStore;
  isEditable?: boolean;
}

const filtersSetup: IBaseGridFilterSetup<NAME_TYPE_FILTERS> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(NAME_TYPE_FILTERS),
  defaultFilterType: NAME_TYPE_FILTERS.NAME,
};

@inject('healthAuthStore')
@observer
class QuarantineLocation extends BaseGrid<Props, IdNameCodeModel, NAME_TYPE_FILTERS> {
  static defaultProps = {
    isEditable: true,
  };
  private readonly alertMessageId: string = 'quarantineLocation';

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private get healthAuthStore(): HealthAuthStore {
    return this.props.healthAuthStore as HealthAuthStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .getQuarantineLocations()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(quarantineLocations => (this.data = quarantineLocations));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        isRequired: true,
        rules: 'required|string|between:1,50',
      },
    },
    {
      headerName: 'Code',
      field: 'code',
      cellEditorParams: {
        isRequired: true,
        rules: 'required|numeric',
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      colId: 'actionRenderer',
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable || !this.props.isEditable,
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      suppressClickEdit: !this.props.isEditable || !SettingsModuleSecurity.isEditable,
      doesExternalFilterPass: node => {
        const { id, name } = node.data as IdNameCodeModel;
        return (
          !id ||
          this._isFilterPass({
            [NAME_TYPE_FILTERS.NAME]: name,
          })
        );
      },
      onRowEditingStopped: () => {
        this._onRowEditingStopped();
        this._setColumnVisible('actionRenderer', this.props.isEditable as boolean);
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
      },
    };
  }

  @action
  private addNewType(): void {
    this._setColumnVisible('actionRenderer', true);
    this._addNewItems([ new IdNameCodeModel({ id: 0 }) ], {
      startEditing: true,
      colKey: 'name',
    });
    this.hasError = true;
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  private isAlreadyExists(id: number): boolean {
    if (this._isAlreadyExists([ 'code' ], id)) {
      this.showAlert('Code should be unique.', this.alertMessageId);
      return true;
    }
    return false;
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertQuarantineLocation(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  private get rightContent(): ReactNode {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.addNewType()}
      >
        Add Quarantine Location
      </PrimaryButton>
    );
  }

  /* istanbul ignore next */
  private upsertQuarantineLocation(rowIndex: number): void {
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .upsertQuarantineLocation(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: IdNameCodeModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as NAME_TYPE_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default QuarantineLocation;
