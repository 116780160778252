import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { HealthVendorContactModel } from '../../../Shared';
import { HealthVendorContact } from '../index';
import { Field } from 'mobx-react-form';
import { IClasses, IOptionValue, UnsubscribableComponent } from '@wings-shared/core';
import { Typography, withStyles } from '@material-ui/core';
import { styles } from './HealthVendorTabs.style';

type Props = {
  classes?: IClasses;
  isEditable: boolean;
  contacts: HealthVendorContactModel[];
  onUpdate: (contacts: HealthVendorContactModel, removeContact?: boolean) => void;
  onContactEditing: (isEditing: boolean) => void;
  getField: (fieldKey: string) => Field;
  onChange: (value: IOptionValue, fieldKey: string) => void;
};
@observer
export class HealthVendorTabs extends UnsubscribableComponent<Props> {
  render(): ReactNode {
    const { contacts, onUpdate, onContactEditing, isEditable, getField, onChange, classes } = this.props;
    const _classes = classes as IClasses;
    return (
      <>
        <Typography variant="h6" className={_classes.title}>
          Contact Info:
        </Typography>
        <div className={_classes.wrapper}>
          <HealthVendorContact
            isEditable={isEditable}
            contacts={contacts}
            onUpdate={(contact, removeContact) => onUpdate(contact, removeContact)}
            onContactEditing={isEditing => onContactEditing(isEditing)}
            getField={(fieldKey: string) => getField(fieldKey)}
            onChange={(value, fieldKey) => onChange(value, fieldKey)}
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(HealthVendorTabs);
