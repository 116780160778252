import React, { ReactNode, FC, useState, useEffect, useRef, RefObject, useMemo } from 'react';
import { ModelStatusOptions, VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { ColDef, ColGroupDef, GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  ScheduleRestrictionsStore,
  ScheduleRestrictionsModel,
  SCHEDULE_RESTRICTIONS_FILTERS,
  RestrictionModuleSecurity,
  updateRestrictionSidebarOptions,
} from '../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { gridFilters } from './fields';
import {
  DATE_FORMAT,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  ViewPermission,
  SearchStore,
  GRID_ACTIONS,
  cellStyle,
  StatusTypeModel,
} from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  viewMode?: VIEW_MODE;
  scheduleRestrictionsStore?: ScheduleRestrictionsStore;
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
}

const ScheduleRestrictionsV2: FC<Props> = ({ ...props }) => {
  const gridState = useGridState();
  const agGrid = useAgGrid<SCHEDULE_RESTRICTIONS_FILTERS, ScheduleRestrictionsModel>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  /* eslint-disable max-len */
  const _scheduleRestrictionsStore = props.scheduleRestrictionsStore as ScheduleRestrictionsStore;
  const _sidebarStore = props.sidebarStore as typeof SidebarStore;

  /* istanbul ignore next */
  useEffect(() => {
    _sidebarStore?.setNavLinks(updateRestrictionSidebarOptions('Schedule Restrictions'), 'restrictions');
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      /* istanbul ignore next */
      SearchStore.clearSearchData(location.pathname);
      return;
    }

    loadScheduleRestrictions();
    agGrid.filtersApi.onAdvanceFilterChange$.subscribe(() => loadScheduleRestrictions());
  }, []);

  /* istanbul ignore next */
  const isStatusFilter = () =>
    Utilities.isEqual(searchHeaderRef.current?.selectedOption, SCHEDULE_RESTRICTIONS_FILTERS.STATUS);

  /* istanbul ignore next */
  const __searchFilters = (): IAPIGridRequest => {
    const chip = searchHeaderRef.current?.getFilters().chipValue?.valueOf() as StatusTypeModel[];
    if (isStatusFilter() && chip?.length) {
      return {
        filterCollection: JSON.stringify([{ propertyName: 'Status.Name', propertyValue: chip[0]?.name || '' }]),
      };
    }
    return agGrid.filtersApi.getSearchFilters(
      searchHeaderRef.current?.searchValue || '',
      searchHeaderRef.current?.selectedOption
    );
  };

  /* istanbul ignore next */
  const loadScheduleRestrictions = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ...__searchFilters(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...agGrid.filtersApi.getAdvancedSearchFilters(),
    };

    UIStore.setPageLoader(true);
    _scheduleRestrictionsStore
      .getScheduleRestrictions(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: IAPIPageResponse) => {
        gridState.setPagination(new GridPagination({ ...response }));
        gridState.setGridData(response.results);
        agGrid.filtersApi.gridAdvancedSearchFilterApplied();
      });
  };

  /* istanbul ignore next */
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Restriction Type',
      field: 'restrictionType',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('restrictionType', 2),
    },
    {
      headerName: 'Restricting Entity',
      field: 'restrictingEntities',
      cellRenderer: 'agGridChipView',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('restrictingEntities', 2),
      cellRendererParams: {
        tooltipField: 'entityName',
      },
    },
    {
      headerName: 'Departure Level',
      field: 'departureLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('departureLevel', 2),
    },
    {
      headerName: 'Departure Entity',
      field: 'departureLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('departureLevelEntities', 2),
    },
    {
      headerName: 'Arrival Level',
      field: 'arrivalLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('arrivalLevel', 2),
    },
    {
      headerName: 'Arrival Entity',
      field: 'arrivalLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('arrivalLevelEntities', 2),
    },
    {
      headerName: 'Overflight Level',
      field: 'overFlightLevel',
      valueFormatter: ({ value }) => value?.name || '',
      comparator: (current, next) => Utilities.customComparator(current, next, 'name'),
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('overFlightLevel', 2),
    },
    {
      headerName: 'Overflight Entity',
      field: 'overFlightLevelEntities',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('overFlightLevelEntities', 2),
    },
    {
      headerName: 'Far Types',
      field: 'farTypes',
      cellRenderer: 'agGridChipView',
      cellRendererParams: {
        tooltipField: 'entityName',
      },
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('farTypes', 2),
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('status', 2, 'start'),
      filterValueGetter: ({ data }: ValueGetterParams) => data.status?.label,
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'other details',
      groupId: 'otherDetails',
      suppressMenu: true,
      children: [
        {
          headerName: 'Start Date',
          field: 'startDate',
          headerComponent: 'customHeader',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'End Date',
          field: 'endDate',
          columnGroupShow: 'open',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'Validated Date',
          field: 'validatedDate',
          columnGroupShow: 'open',
          comparator: (current, next) => Utilities.customDateComparator(current, next),
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT) || '',
        },
        {
          headerName: 'Validated By',
          field: 'validatedBy',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: agGrid.filtersApi.getAdvanceFilterParams('validatedBy', 2),
        },
        {
          headerName: 'Validation Notes',
          field: 'validationNotes',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: agGrid.filtersApi.getAdvanceFilterParams('validationNotes', 2),
        },
        {
          headerName: '',
          field: 'actionRenderer',
          cellRenderer: 'actionRenderer',
          cellEditor: 'actionRenderer',
          filter: false,
          sortable: false,
          suppressSizeToFit: true,
          suppressMenu: true,
          minWidth: 120,
          maxWidth: 150,
          cellStyle: { ...cellStyle() },
        },
      ],
    },
    {
      ...agGrid.actionColumn({
        cellRendererParams: {
          isActionMenu: true,
          onAction: (action: GRID_ACTIONS) => {
            if (searchHeaderRef.current) {
              SearchStore.saveSearchData(location.pathname, {
                ...searchHeaderRef.current.getFilters(),
                pagination: gridState.pagination,
              });
            }
          },
          actionMenus: () => [
            {
              title: 'Edit',
              isHidden: !RestrictionModuleSecurity.isEditable,
              action: GRID_ACTIONS.EDIT,
              to: node => `${node.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
            },
            {
              title: 'Details',
              action: GRID_ACTIONS.VIEW,
              to: node => `${node.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
            },
          ],
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs: columnDefs,
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        cellRendererParams: {
          ...baseOptions.defaultColDef?.cellRendererParams,
          chipLabelField: 'code',
        },
      },
      pagination: false,
      isExternalFilterPresent: () => false,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      onFilterChanged: () => Array.from(gridState.columFilters).length === 0 && loadScheduleRestrictions(),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadScheduleRestrictions();
      },
    };
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={RestrictionModuleSecurity.isEditable}>
        <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Schedule Restriction" />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        selectInputs={[
          agGridUtilities.createSelectOption(
            SCHEDULE_RESTRICTIONS_FILTERS,
            SCHEDULE_RESTRICTIONS_FILTERS.RESTRICTION_TYPE
          ),
        ]}
        onFilterChange={isInitEvent => {
          loadScheduleRestrictions({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        rightContent={rightContent}
        disableControls={Boolean(Array.from(gridState.columFilters).length) || gridState.isRowEditing}
        onExpandCollapse={agGrid.autoSizeColumns}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
        isChipInputControl={isStatusFilter()}
        chipInputProps={{
          options: isStatusFilter() ? ModelStatusOptions : [],
          allowOnlySingleSelect: true,
        }}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadScheduleRestrictions}
      />
    </>
  );
};

export default inject('scheduleRestrictionsStore', 'sidebarStore')(observer(ScheduleRestrictionsV2));
