import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    exportBtn: {
      marginRight: 12,
    },
  });


export const useStyles = makeStyles(() => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  headerActionsEditMode: {
    justifyContent: 'space-between',
  },
}));
