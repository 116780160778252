import React, { ReactNode } from 'react';
import { VIEW_MODE, ModelStatusOptions } from '@wings/shared';
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { HEALTH_AUTH_FILTERS } from '../Shared/Enums';
import { HealthAuthModel, HealthAuthStore, RestrictionModuleSecurity, sidebarOptions } from '../Shared';
import { styles } from './HealthAuth.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { action, observable } from 'mobx';
import {
  DATE_FORMAT,
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  withRouter,
  ViewPermission,
  SearchStore,
  IdNameCodeModel,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { SearchHeader } from '@wings-shared/form-controls';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  IActionMenuItem,
  AgGridActions,
  AgGridDateTimePicker,
  AgGridCheckBox,
  AgGridStatusBadge,
} from '@wings-shared/custom-ag-grid';

interface Props {
  healthAuthStore?: HealthAuthStore;
  sidebarStore?: typeof SidebarStore;
  classes: IClasses;
}

const filtersSetup: IBaseGridFilterSetup<HEALTH_AUTH_FILTERS> = {
  defaultPlaceHolder: 'Search by Level Designator',
  filterTypesOptions: Object.values(HEALTH_AUTH_FILTERS),
  defaultFilterType: HEALTH_AUTH_FILTERS.LEVEL_DESIGNATOR,
};

@inject('healthAuthStore', 'sidebarStore')
@observer
class HealthAuth extends BaseGrid<Props, HealthAuthModel, HEALTH_AUTH_FILTERS> {
  @observable public searchChips: ISelectOption[] = [];

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    const { sidebarStore, healthAuthStore } = this.props;
    sidebarStore?.setNavLinks(sidebarOptions(false), 'restrictions');
    healthAuthStore?.setSelectedHealthAuthorization(new HealthAuthModel());
    this.loadHealthAuths();
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    if (SearchStore.clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private get healthAuthStore(): HealthAuthStore {
    return this.props.healthAuthStore as HealthAuthStore;
  }

  private get isStatusFilter(): boolean {
    return Utilities.isEqual(this.selectedOption, HEALTH_AUTH_FILTERS.STATUS);
  }

  /* istanbul ignore next */
  private loadHealthAuths(): void {
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .getHealthAuths(true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        this.data = response.results;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption((selectedOption as HEALTH_AUTH_FILTERS) || HEALTH_AUTH_FILTERS.LEVEL_DESIGNATOR);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private get actionMenus(): IActionMenuItem[] {
    return [
      {
        title: 'Edit',
        isHidden: !RestrictionModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
        to: node => `health-authorization/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
      },
      {
        title: 'Details',
        action: GRID_ACTIONS.DETAILS,
        isHidden: false,
        to: node => `health-authorization/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
      },
    ];
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Level',
      field: 'authorizationLevel',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.authorizationLevel.name,
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Level Designator',
      field: 'levelDesignator',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.levelDesignator.label,
      comparator: (current: IdNameCodeModel, next: IdNameCodeModel) =>
        Utilities.customComparator(current, next, 'label'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      minWidth: 230,
    },
    {
      headerName: 'Disease or Virus',
      field: 'infectionType',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.infectionType.name,
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Affected Type',
      field: 'affectedType',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.affectedType.name,
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Nationality Affected',
      field: 'healthAuthNationalities',
      sortable: false,
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        data.isAllNationalities ? 'All' : data.healthAuthNationalities?.map(v => v.isO2Code).join(', '),
      valueFormatter: ({ value, data }: ValueFormatterParams) =>
        data.isAllNationalities ? 'All' : value?.map(v => v.isO2Code).join(', '),
    },
    {
      headerName: 'Traveled Country',
      field: 'healthAuthTraveledCountries',
      sortable: false,
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        data.isAllTraveledCountries ? 'All' : data.healthAuthTraveledCountries?.map(v => v.isO2Code).join(', '),
      valueFormatter: ({ value, data }: ValueFormatterParams) =>
        data.isAllTraveledCountries ? 'All' : value?.map(v => v.isO2Code).join(', '),
    },
    {
      headerName: 'Suspend Automated Email',
      field: 'isSuspendNotification',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
      headerTooltip: 'Suspend Automated Email',
    },
    {
      headerName: 'Received Date',
      field: 'receivedDate',
      cellEditor: 'customTimeEditor',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.receivedDate, DATE_FORMAT.DATE_PICKER_FORMAT),
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Modified Date',
      field: 'modifiedOn',
      cellEditor: 'customTimeEditor',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.modifiedOn, DATE_FORMAT.DATE_PICKER_FORMAT),
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Requested Date',
      field: 'requestedDate',
      cellEditor: 'customTimeEditor',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.requestedDate, DATE_FORMAT.DATE_PICKER_FORMAT),
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: true,
      cellRenderer: 'statusRenderer',
      filterValueGetter: ({ data }: ValueGetterParams) => data.status?.label,
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => this.actionMenus,
        onAction: () => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue as any);
          }
        },
      },
    },
  ];

  /* istanbul ignore next */
  private setSelectedOption(selectedOption: HEALTH_AUTH_FILTERS): void {
    this.searchChips = [];
    this._setSelectedOption(selectedOption);
    this.gridApi?.onFilterChanged();
  }

  /* istanbul ignore next */
  @action
  protected onChipAddOrRemove(searchChips: ISelectOption[]): void {
    // if multiple items then select last item
    this.searchChips = searchChips?.length > 1 ? [].concat(searchChips?.pop() as any) : searchChips;
    this._setSearchValue(this.searchChips[0]?.label);
    if (!this.gridApi) {
      return;
    }
    this.gridApi.onFilterChanged();
  }

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'Health Auth',
      getDisabledState: () => this.hasError,
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const {
          levelDesignator,
          id,
          isAllNationalities,
          isAllTraveledCountries,
          healthAuthNationalities,
          healthAuthTraveledCountries,
          status,
        } = node.data as HealthAuthModel;
        const isExactMatch = Utilities.isEqual(this.selectedOption, HEALTH_AUTH_FILTERS.STATUS);
        return (
          !id ||
          this._isFilterPass(
            {
              [HEALTH_AUTH_FILTERS.LEVEL_DESIGNATOR]: levelDesignator?.label,
              [HEALTH_AUTH_FILTERS.NATIONALITY]: isAllNationalities
                ? 'All'
                : healthAuthNationalities?.map(x => x.isO2Code).join(', '),
              [HEALTH_AUTH_FILTERS.TRAVELED_COUNTRY]: isAllTraveledCountries
                ? 'All'
                : healthAuthTraveledCountries?.map(x => x.isO2Code).join(', '),
              [HEALTH_AUTH_FILTERS.STATUS]: status?.label || '',
            },
            isExactMatch
          )
        );
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customTimeEditor: AgGridDateTimePicker,
        checkBoxRenderer: AgGridCheckBox,
        statusRenderer: AgGridStatusBadge,
      },
    };
  }

  private exportHealthAuthExcel(): void {
    UIStore.setPageLoader(true);
    this.healthAuthStore
      .getHealthAuthExcelFile()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((file: File) => {
        const url = window.URL.createObjectURL(new Blob([ file ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'HealthAuthorization.xlsx');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
  }

  private get rightContent(): ReactNode {
    const { classes } = this.props;
    return (
      <>
        <PrimaryButton variant="contained" disabled={this.isProcessing} onClick={() => this.exportHealthAuthExcel()}>
          Export Excel
        </PrimaryButton>
        <ViewPermission hasPermission={RestrictionModuleSecurity.isEditable}>
          <CustomLinkButton
            variant="contained"
            startIcon={<AddIcon />}
            to={`health-authorization/${VIEW_MODE.NEW.toLowerCase()}`}
            title="Add Health Authorization"
            disabled={this.isProcessing}
          />
        </ViewPermission>
      </>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this.setSelectedOption(selectedOption as HEALTH_AUTH_FILTERS)}
          options={Utilities.isEqual(this.selectedOption, HEALTH_AUTH_FILTERS.STATUS) ? ModelStatusOptions : []}
          isChipInputControl={this.isStatusFilter}
          chipValue={this.searchChips}
          onChipAddOrRemove={(chips: ISelectOption[]) => this.onChipAddOrRemove(chips)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact isRowEditing={this.isRowEditing} rowData={this.data} gridOptions={this.gridOptions} />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(HealthAuth));
export { HealthAuth as PureHealthAuth };
